import React from 'react';
import s from './StatusField.module.scss';
import { Controller, FieldValues, useFormContext, UseFormSetValue } from 'react-hook-form';
import { Select } from '@mui/material';
import { DataPointMeta, StatusType } from 'src/pages/company/_BLL/types';
import MenuItem from '@mui/material/MenuItem';

interface Props {
	namePrefix: string;
	propertyName: string;
	dataPoints: DataPointMeta[];
}

export const StatusField: React.FC<Props> = props => {
	const { namePrefix, propertyName, dataPoints } = props;

	// * Form
	const { setValue, control } = useFormContext();

	const getControlFieldName = () => {
		if (dataPoints.find(dataPoint => dataPoint.internalName === 'existence')) {
			return `${namePrefix}${propertyName}.existence.status`;
		} else if (dataPoints.find(dataPoint => dataPoint.internalName === 'sector')) {
			return `${namePrefix}${propertyName}.sector.status`;
		} else if (dataPoints.find(dataPoint => dataPoint.internalName === 'delta')) {
			return `${namePrefix}${propertyName}.delta.status`;
		} else {
			return `${namePrefix}${propertyName}.dataPoint.status`;
		}
	};

	const ValueFieldNames = dataPoints.map(dataPoint => {
		if (dataPoint.allowsMany) {
			return `${namePrefix}${propertyName}.${dataPoint.internalName}.values`;
		} else {
			return `${namePrefix}${propertyName}.${dataPoint.internalName}.value`;
		}
	});

	const StatusFieldNames = dataPoints.map(dataPoint => {
		return `${namePrefix}${propertyName}.${dataPoint.internalName}.status`;
	});

	const VerifyFieldNames = dataPoints.map(dataPoint => {
		return `${namePrefix}${propertyName}.${dataPoint.internalName}.verified`;
	});

	const changeStatus = (status: StatusType.EMPTY | StatusType.UNKNOWN, setFieldValue: UseFormSetValue<FieldValues>) => {
		StatusFieldNames.forEach(name => {
			setFieldValue(name, status);
		});

		ValueFieldNames.forEach(name => {
			setFieldValue(name, null);
		});

		VerifyFieldNames.forEach(name => {
			setFieldValue(name, false);
		});
	};

	const getStatusBackground = (status: StatusType) => {
		if (status === StatusType.FILLED) return '#c0ffd3';
		if (status === StatusType.EMPTY) return '#E5E8F2';
		if (status === StatusType.UNKNOWN) return '#FFD9D9';
	};

	// * Render
	return (
		<Controller
			name={getControlFieldName()}
			control={control}
			render={({ field }) => (
				<div>
					<span className={s.label}>Status</span>

					<Select
						{...field}
						className={s.select}
						style={{
							backgroundColor: getStatusBackground(field.value),
						}}
						size="small"
						onChange={() => {
							// ignore
						}}
						value={field.value}
					>
						<MenuItem
							value={StatusType.EMPTY}
							onClick={() => changeStatus(StatusType.EMPTY, setValue)}
						>
							Empty
						</MenuItem>

						<MenuItem
							value={StatusType.UNKNOWN}
							onClick={() => changeStatus(StatusType.UNKNOWN, setValue)}
						>
							Unavailable
						</MenuItem>

						<MenuItem value={StatusType.FILLED}>Filled</MenuItem>
					</Select>
				</div>
			)}
		/>
	);
};
