import React, { ChangeEvent } from 'react';
import s from './StringField.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';
import { GeneratedField, StatusType } from 'src/pages/company/_BLL/types';
import { ValueTooltip } from 'src/shared/ui/ValueTooltip';
import { nullToString } from 'src/pages/company/ui/dataPointGenerator/fields/_helpers/valueHelpers';

export const StringField: React.FC<GeneratedField> = props => {
	const { namePrefix, propertyName, internalName, measureUnits, validate } = props;

	// * Form
	const { setValue, control, trigger } = useFormContext();

	const name = `${namePrefix}${propertyName}.${internalName}`;

	const textFieldOnChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, fieldName: string) => {
		if (event.currentTarget.value.trim() === '') {
			setValue(fieldName, null);
			setValue(`${name}.status`, StatusType.EMPTY);
		} else {
			setValue(fieldName, event.target.value);
			setValue(`${name}.status`, StatusType.FILLED);
		}
	};

	// * Render
	return (
		<Controller
			name={`${name}.value`}
			control={control}
			rules={{ validate: validate }}
			render={({ field, fieldState }) => (
				<div>
					<div className={s.fieldWrapper}>
						<TextField
							{...field}
							className={s.textField}
							value={nullToString(field.value)}
							onChange={event => textFieldOnChangeHandler(event, field.name)}
							onBlur={event => (field.value !== null ? setValue(field.name, event.currentTarget.value.trim(), { shouldValidate: true }) : trigger())}
							size="small"
						/>
						{measureUnits && <span className={s.additionalTextRight}>{measureUnits}</span>}

						<ValueTooltip title={field.value} />
					</div>

					{fieldState.error && <span className={s.error}>{fieldState.error.message}</span>}
				</div>
			)}
		/>
	);
};
