import React, { ChangeEvent, memo } from 'react';
import s from './IntegerFloatField.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { InputAdornment, TextField } from '@mui/material';
import { GeneratedField, StatusType } from 'src/pages/company/_BLL/types';

interface Props extends GeneratedField {
	valueType: 'Integer' | 'Float' | 'Id' | 'YearAge' | 'AgeFloat';
}

export const IntegerFloatField: React.FC<Props> = memo(props => {
	const {
		valueType, //
		namePrefix,
		propertyName,
		internalName,
		measureUnits,
		validate,
	} = props;

	// * Form
	const { setValue, control, trigger } = useFormContext();

	const name = `${namePrefix}${propertyName}.${internalName}`;

	const textFieldOnChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, fieldName: string) => {
		if (event.target.value === '') {
			setValue(fieldName, null);
			setValue(`${name}.status`, StatusType.EMPTY);
		} else if (valueType === 'Integer' || valueType === 'Id' || valueType === 'YearAge') {
			if (event.target.value.match(/^[0-9,]+$/g)) {
				const value = event.target.value.replace(/,/g, '');

				setValue(fieldName, value);
				setValue(`${name}.status`, StatusType.FILLED);
			}
		} else if (valueType === 'Float' || valueType === 'AgeFloat') {
			if (event.target.value.match(/^[0-9,]*\.?[0-9,]*$/g)) {
				const value = event.target.value.replace(/,/g, '');

				setValue(fieldName, value);
				setValue(`${name}.status`, StatusType.FILLED);
			}
		} else return;
	};

	const fakeValue = (value: string) => {
		if (valueType !== 'Id' && propertyName !== 'birthYear' && valueType !== 'AgeFloat' && valueType !== 'YearAge') {
			const fakeValue = value.replace(/,/g, '');
			return fakeValue.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
		}

		return value;
	};

	// * Component
	return (
		<Controller
			name={`${name}.value`}
			control={control}
			rules={{ validate: validate }}
			render={({ field, fieldState }) => (
				<div>
					<div className={s.fieldWrapper}>
						{/* Hidden input */}
						<input
							{...field}
							className={s.input}
							value={field.value === null ? '' : field.value}
						/>

						<TextField
							{...field}
							className={s.textField}
							value={field.value === null ? '' : fakeValue(field.value)}
							onChange={e => textFieldOnChangeHandler(e, field.name)}
							onBlur={() => trigger()}
							size="small"
							InputProps={{
								endAdornment: <InputAdornment position="end">{measureUnits}</InputAdornment>,
							}}
						/>
					</div>

					{fieldState.error && <span className={s.error}>{fieldState.error.message}</span>}
				</div>
			)}
		/>
	);
});
