import React from 'react';

interface Props {
	yearOfIssue: number,
}

export const Widget: React.FC<Props> = props => {
	const {
		yearOfIssue
	} = props

	// * Render
	return (
		<svg width="427.5" height="125.5" viewBox="0 0 855 251" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_2415_94)">
				<rect width="855" height="251" rx="5" fill="white"/>
				<rect x="2.5" y="2.5" width="850" height="246" fill="white" stroke="#02356C" strokeWidth="5"/>
				<rect x="18.5" y="21.5" width="212" height="208" rx="4.5" fill="#02356C"/>
				<rect x="18.5" y="21.5" width="212" height="208" rx="4.5" stroke="#02356C"/>
				<g clipPath="url(#clip1_2415_94)">
					<path d="M36 114.437V117.866H213V111H36V114.437Z" fill="white"/>
					<path d="M132.939 120.717C132.645 120.858 132.405 121.091 132.255 121.379C132.106 121.667 132.054 121.996 132.108 122.316C132.11 122.564 132.162 122.809 132.26 123.037C132.358 123.265 132.5 123.472 132.678 123.645C132.857 123.818 133.068 123.955 133.3 124.047C133.532 124.139 133.78 124.184 134.03 124.181C134.378 124.183 134.72 124.085 135.013 123.897C135.262 123.742 135.471 123.531 135.622 123.28C135.773 123.03 135.862 122.748 135.882 122.457C135.902 122.165 135.852 121.874 135.737 121.605C135.622 121.337 135.444 121.099 135.219 120.912C134.907 120.648 134.519 120.486 134.11 120.451C133.702 120.416 133.292 120.509 132.939 120.717Z" fill="white"/>
					<path d="M48.7743 121.818C48.0971 121.863 47.4228 121.943 46.7541 122.058L46.3696 122.183V139.476L46.8524 139.574C47.1116 139.645 48.6403 139.734 50.2583 139.787C51.6663 139.921 53.0861 139.861 54.4777 139.609C56.1515 139.295 57.7015 138.516 58.9474 137.362C60.7352 135.666 61.4325 133.809 61.4325 130.754C61.4325 126.162 59.2692 123.231 55.0945 122.165C53.0154 121.749 50.8869 121.632 48.7743 121.818ZM54.9783 125.069C56.1931 125.683 57.145 126.711 57.6601 127.965C58.0115 129.075 58.1372 130.244 58.0297 131.402C57.9223 132.561 57.584 133.687 57.0344 134.715C55.9974 136.314 54.576 136.936 51.6707 137.069L49.6325 137.14V124.536L51.8405 124.598C52.9086 124.522 53.9804 124.683 54.9783 125.069Z" fill="white"/>
					<path d="M170.914 122.254C170.824 122.254 170.172 122.405 169.474 122.511L168.214 122.716L168.268 129.706L168.321 136.678L168.786 137.655C169.045 138.229 169.442 138.731 169.943 139.117C170.443 139.503 171.031 139.76 171.656 139.867C173.029 140.084 174.433 139.996 175.768 139.609L176.662 139.334L176.42 138.188C176.352 137.772 176.241 137.365 176.089 136.971C175.856 136.964 175.624 137.01 175.41 137.105C174.387 137.418 173.289 137.393 172.281 137.033C171.459 136.483 171.334 135.888 171.334 132.21V128.862H176.17V126.286H171.343V124.226C171.382 123.542 171.343 122.857 171.226 122.183C171.119 122.188 171.013 122.212 170.914 122.254Z" fill="white"/>
					<path d="M68.7179 126.18C65.8037 127.13 64.1768 129.635 64.1768 133.134C64.213 134.392 64.4827 135.633 64.9723 136.794C65.6512 137.944 66.6553 138.87 67.8598 139.458C68.8431 139.902 69.1023 139.938 71.0064 139.956C72.4354 140.046 73.8679 139.846 75.2169 139.369C75.5834 139.147 75.1364 136.705 74.752 136.865C73.0898 137.338 71.3406 137.426 69.6387 137.122C69.0576 136.917 68.5456 136.555 68.1603 136.076C67.7749 135.598 67.5316 135.022 67.4575 134.413V134.031H76.2717V132.61C76.2733 131.89 76.2165 131.172 76.1019 130.461C75.9317 129.602 75.5661 128.793 75.0332 128.095C74.5002 127.398 73.8139 126.831 73.0267 126.437C71.6409 125.963 70.151 125.874 68.7179 126.18ZM71.7842 128.897C72.1451 129.104 72.4525 129.392 72.6822 129.737C72.9118 130.082 73.0574 130.476 73.1072 130.887L73.2145 131.607H70.3271C67.1268 131.607 67.2161 131.607 67.7167 130.443C67.8492 130.054 68.0662 129.699 68.3524 129.403C68.6385 129.107 68.9867 128.876 69.3723 128.729C69.758 128.581 70.1715 128.52 70.5837 128.549C70.9959 128.578 71.3966 128.697 71.7573 128.897H71.7842Z" fill="white"/>
					<path d="M82.6902 126.117C82.0824 126.197 81.1795 126.357 80.661 126.455L79.7671 126.65V139.68H82.7081V128.897L83.1015 128.791C84.2123 128.619 85.3468 128.673 86.4358 128.951C86.6666 129.048 86.8741 129.192 87.0451 129.374C87.216 129.557 87.3466 129.772 87.4282 130.008C87.7679 130.78 87.8036 131.162 87.8572 135.248L87.9288 139.689H91.0039V135.861C91.0039 133.765 90.9145 131.464 90.8251 130.763C90.5212 128.462 89.4842 126.979 87.7321 126.384C86.0809 125.997 84.3734 125.907 82.6902 126.117Z" fill="white"/>
					<path d="M98.6562 126.162C95.286 127.281 93.5428 130.372 94.0791 134.235C94.5797 137.673 96.761 139.822 100.06 139.991C104.136 140.213 106.871 137.38 106.871 132.885C106.947 131.567 106.632 130.256 105.964 129.114C105.297 127.973 104.307 127.051 103.117 126.464C101.691 125.935 100.141 125.83 98.6562 126.162ZM101.874 128.951C103.135 129.599 103.895 131.571 103.662 133.623C103.385 136.349 101.722 137.842 99.6126 137.273C97.9946 136.847 97.1632 135.39 97.1632 133.001C97.1632 130.958 97.789 129.528 98.9511 128.977C99.8903 128.618 100.929 128.608 101.874 128.951Z" fill="white"/>
					<path d="M113.227 126.117C112.611 126.18 111.654 126.357 111.118 126.473L110.152 126.677V139.68H113.263V134.289C113.263 129.528 113.263 128.88 113.54 128.791C114.544 128.613 115.575 128.667 116.553 128.951C117.599 129.484 117.67 129.839 117.724 135.008L117.778 139.68H120.924L120.871 134.84C120.891 133.114 120.822 131.389 120.665 129.67C120.334 128.977 123.132 128.382 124.241 128.897C124.606 129.138 124.894 129.477 125.072 129.874C125.448 130.629 125.466 130.905 125.519 135.204L125.591 139.707H128.702L128.612 134.937C128.532 129.919 128.407 129.057 127.718 127.974C126.35 125.913 123.15 125.416 120.299 126.81C119.279 127.325 119.262 127.325 118.922 126.997C118.001 126.144 115.722 125.806 113.227 126.117Z" fill="white"/>
					<path d="M142.611 126.117C141.994 126.197 141.038 126.357 140.51 126.473L139.536 126.677V139.68H142.647V128.897L143.049 128.791C144.168 128.569 145.326 128.652 146.402 129.031C147.51 129.706 147.573 129.999 147.626 135.079L147.68 139.68H150.809L150.737 134.822C150.666 130.319 150.657 129.91 150.29 129.031C149.289 126.508 146.938 125.611 142.611 126.117Z" fill="white"/>
					<path d="M156.682 126.117C155.421 126.339 154.831 126.597 154.939 126.872C155.039 127.266 155.108 127.667 155.144 128.071C155.252 128.862 155.314 129.031 155.564 128.959C155.815 128.888 156.253 128.8 156.771 128.702C159.757 128.151 161.456 128.977 161.456 130.985V131.74L160.472 131.58C157.656 131.118 154.814 132.148 154.009 133.916C153.639 134.791 153.556 135.761 153.773 136.686C153.99 137.611 154.495 138.444 155.216 139.067C156.61 140.035 159.685 140.24 163.261 139.636L164.468 139.414V134.537C164.468 130.345 164.468 129.537 164.173 128.888C164.007 128.37 163.733 127.892 163.367 127.486C163.002 127.08 162.555 126.756 162.054 126.535C161.205 126.123 160.261 125.946 159.319 126.02C158.439 125.981 157.557 126.013 156.682 126.117ZM160.919 133.774L161.456 133.88V137.433L160.347 137.548C158.881 137.691 157.585 137.415 157.129 136.847C156.892 136.497 156.77 136.083 156.78 135.661C156.79 135.24 156.93 134.832 157.182 134.493C157.808 133.791 159.489 133.48 160.955 133.738L160.919 133.774Z" fill="white"/>
					<path d="M182.606 126.25C179.844 127.139 178.136 129.741 178.136 132.983C178.136 135.168 179.209 137.673 180.577 138.703C181.11 139.161 181.731 139.508 182.402 139.723C183.073 139.938 183.781 140.017 184.483 139.956C185.508 140.09 186.55 139.89 187.451 139.387C189.409 138.446 190.508 136.811 190.911 134.324C191.429 130.976 189.909 127.707 187.335 126.526C185.831 125.923 184.17 125.826 182.606 126.25ZM186.083 129.013C186.527 129.316 186.906 129.702 187.201 130.15C187.701 130.958 187.719 131.038 187.719 132.965C187.719 134.893 187.719 135.026 187.29 135.728C187.079 136.16 186.764 136.535 186.373 136.819C185.982 137.103 185.527 137.287 185.048 137.355C184.569 137.424 184.08 137.374 183.625 137.211C183.17 137.048 182.761 136.777 182.436 136.42C181.676 135.612 181.345 134.546 181.363 132.779C181.294 131.974 181.469 131.167 181.864 130.461C182.045 129.908 182.408 129.432 182.895 129.11C183.381 128.788 183.963 128.638 184.546 128.684C185.062 128.681 185.572 128.793 186.039 129.013H186.083Z" fill="white"/>
					<path d="M197.874 126.046C197.597 126.046 196.686 126.286 195.854 126.473L194.334 126.837V139.68H197.267V129.022L197.758 128.835C199.046 128.663 200.353 128.699 201.629 128.942C201.853 129.022 201.942 128.871 202.112 128.054C202.21 127.503 202.353 126.917 202.389 126.766C202.425 126.615 202.317 126.437 201.817 126.304C200.517 126.065 199.194 125.978 197.874 126.046Z" fill="white"/>
					<path d="M132.447 132.983V139.68H135.567V126.286H132.447V132.983Z" fill="white"/>
				</g>
				<line x1="259.5" y1="21" x2="259.5" y2="230" stroke="#02356C" strokeWidth="3"/>
				<text fill="#02356C" xmlSpace="preserve" style={{whiteSpace: 'pre'}} fontFamily="Arial" fontSize="38" fontWeight="bold" letterSpacing="0em"><tspan x="287" y="94.6738">{yearOfIssue} </tspan><tspan x="287" y="138.674">Diversity, Equity, Inclusion </tspan></text>
				<text fill="#02356C" xmlSpace="preserve" style={{whiteSpace: 'pre'}} fontFamily="Arial" fontSize="38" letterSpacing="0em"><tspan x="382.186" y="94.6738">Denominator &#xa9; certified</tspan><tspan x="287" y="182.674">score &#38; rating</tspan></text>
			</g>
			<defs>
				<clipPath id="clip0_2415_94">
					<rect width="855" height="251" rx="5" fill="white"/>
				</clipPath>
				<clipPath id="clip1_2415_94">
					<rect width="177" height="29" fill="white" transform="translate(36 111)"/>
				</clipPath>
			</defs>
		</svg>
	);
};
