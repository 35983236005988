import React from 'react';
import s from './PageDecorator.module.scss';
import { Header } from 'src/entities/Header';
import { DivProps } from 'src/shared/types/props';
import cn from 'classnames';

interface Props extends DivProps {

}

export const PageDecorator: React.FC<Props> = props => {
	const {
		children,
		className,
		...divProps
	} = props;

	// * Render
	return (
		<div {...divProps} className={cn(s.container, className)}>
			<Header/>

			<div className={s.content}>
				{children}
			</div>

			{/* footer. If footer is required he must contain margin-top: auto; for it to be stuck to the bottom of the page. */}
		</div>
	);
};
