import React, { memo } from 'react';
import s from './BooleanDropDown.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { MenuItem, Select } from '@mui/material';
import { GeneratedField, StatusType } from 'src/pages/company/_BLL/types';

interface Props extends GeneratedField {
	options?: string[];
}

export const BooleanDropDown: React.FC<Props> = memo(props => {
	const {
		namePrefix, //
		propertyName,
		internalName,
		validate,
		options,
	} = props;

	// * Form
	const { setValue, control } = useFormContext();

	const name = `${namePrefix}${propertyName}.${internalName}`;

	const onValueChange = (fieldName: string, value: unknown) => {
		if (value === null) {
			setValue(`${name}.status`, StatusType.EMPTY);
		} else {
			setValue(`${name}.status`, StatusType.FILLED);
		}

		setValue(fieldName, value, { shouldValidate: true });
	};

	// * Component
	return (
		<Controller
			name={`${name}.value`}
			control={control}
			rules={{ validate: validate }}
			render={({ field, fieldState }) => (
				<div>
					<div className={s.fieldWrapper}>
						{options ? (
							<Select
								{...field}
								className={s.select}
								onChange={e => onValueChange(field.name, e.target.value)}
							>
								{/*@ts-ignore*/}
								<MenuItem value={null}>Unknown</MenuItem>
								<MenuItem value={'true'}>{options[1]}</MenuItem>
								<MenuItem value={'false'}>{options[0]}</MenuItem>
							</Select>
						) : (
							<Select
								{...field}
								className={s.select}
								onChange={e => onValueChange(field.name, e.target.value)}
							>
								{/*@ts-ignore*/}
								<MenuItem value={null}>Unknown</MenuItem>
								<MenuItem value={'true'}>Yes</MenuItem>
								<MenuItem value={'false'}>No</MenuItem>
							</Select>
						)}
					</div>

					{fieldState.error && <span className={s.error}>{fieldState.error.message}</span>}
				</div>
			)}
		/>
	);
});
