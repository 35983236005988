import { SignInValues, UserData } from './types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiRequest } from 'src/app/api/api';
import { RequestStatus, UrlAPI } from 'src/app/api/types';

// * API requests
const signIn = createAsyncThunk('auth/signIn', async (payload: SignInValues, thunkAPI) => {
	return await apiRequest.postRequest<UserData>({
		url: UrlAPI.login,
		payload,
		thunkAPI
	});

	// ! unwrap - after successful execution getMeta() and getDictionaries() are dispatched in SignInForm.
});

// * Reducer
export interface State {
	userData: UserData | null;
	status: RequestStatus;
}

export const initialState: State = {
	userData: null,
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logout: state => {
			state.userData = null;
		},
		storeUserData: (state, action: { payload: UserData }) => {
			state.userData = action.payload;
		}
	},
	extraReducers: builder => {
		builder.addCase(signIn.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(signIn.fulfilled, (state, action) => {
			state.userData = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(signIn.rejected, state => {
			state.status = RequestStatus.failed;
		});
	}
});

export const actionsAuth = {
	...slice.actions,
	signIn
};
