import { DictionaryItem } from 'src/pages/app/_BLL/metaDictionaries/types';

export const uniqueArray = <T extends number | string>(array: T[]) => {
	return Array.from(new Set(array));
};

export const ObjectToArray = (object: any) => {
	return Object.entries(object).map((e) => ({name: e[0], data: e[1]}));
};

export const dictionaryIdToString = (value: string, dictionary: DictionaryItem[]) => {
	if (value === null) return '';

	const dictionaryItem = dictionary.find(item => +value === item.id);

	if (dictionaryItem) {
		return dictionaryItem.name;
	} else {
		return value;
	}
};

export const valuesToString = (value: string[], dictionary?: DictionaryItem[]) => {

	if (value === null || value === undefined) return '';

	let valueAsString = '';

	if (dictionary) {
		value.forEach((value: string, index: number) => {
			const tempValue = dictionary.find(item => +value === item.id);

			if (index === 0 && tempValue) {
				valueAsString = `${tempValue.name}`;
			} else if (tempValue) {
				valueAsString = valueAsString.concat(`,\n${tempValue.name}`);
			}
		});
	} else {
		value.forEach((value: string, index: number) => {
			if (index === 0) {
				valueAsString = value;
			} else {
				valueAsString = valueAsString.concat(`,\n${value}`);
			}
		});
	}

	return valueAsString;
};

export const removeDuplicates = (array: Array<any>, filterValue: string | number) => {
	const newArray = [];
	const lookupObject: any = {};

	for (const i in array) {
		lookupObject[array[i][filterValue]] = array[i];
	}

	for (const i in lookupObject) {
		newArray.push(lookupObject[i]);
	}
	return newArray;
};
