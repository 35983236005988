import React, { memo } from 'react';
import s from './ButtonRounded.module.scss';
import { Preloader } from 'src/shared/ui/loaders/Preloader';
import { Button, ButtonProps } from '@mui/material';
import { RequestStatus } from 'src/app/api/types';

interface Props extends ButtonProps {
	status?: RequestStatus;
}

export const ButtonRounded: React.FC<Props> = memo(props => {

	const {
		status,
		disabled,
		children,
		...buttonProps
	} = props;

	const renderLoader = () => {
		return status === RequestStatus.loading ? <Preloader style={{width: '1rem', height: '1rem'}}/> : '';
	};

	const isDisabled = () => {
		if (status !== undefined) {
			return status === RequestStatus.loading;
		} else {
			return disabled;
		}
	};

	// * Render
	return (
		<Button
			{...buttonProps}
			className={s.container}
			disabled={isDisabled()}
			variant="outlined"
			color="primary"
		>
			<span className={s.inner}>
				{children}
				{renderLoader()}
			</span>
		</Button>
	);
});
