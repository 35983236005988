import { CompaniesInfoRES } from 'src/pages/MainPage/_BLL/types';
import { RequestStatus, UrlAPI } from 'src/app/api/types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiRequest } from 'src/app/api/api';

const NAME = 'userInfo';

// * Thunks
const getCompanies = createAsyncThunk(`${NAME}/getCompanies`, async (payload: void, thunkAPI) => {
	return await apiRequest.getRequest<CompaniesInfoRES>({
		url: UrlAPI.getCompaniesInfo,
		thunkAPI
	});
});

// * Reducer
interface State {
	companiesInfo: CompaniesInfoRES | null;
	status: RequestStatus;
}

export const initialState: State = {
	companiesInfo: null,
	status: RequestStatus.still
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getCompanies.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getCompanies.fulfilled, (state, action) => {
			state.companiesInfo = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(getCompanies.rejected, state => {
			state.status = RequestStatus.failed;
		});
	}
});

export const actionsUserInfo = {
	...slice.actions,
	getCompanies
};

