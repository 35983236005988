import React, { useEffect } from 'react';
import s from './Welcome.module.scss';
import { DivProps } from 'src/shared/types/props';
import cn from 'classnames';
import { DenominatorSubLogo } from 'src/shared/ui/DenominatorSubLogo';
import { CompanyCard } from 'src/widgets/CompanyCard';
import { useAppDispatch, useAppSelector } from 'src/app/BLL/createAction';
import { actionsUserInfo } from 'src/pages/MainPage/_BLL/slice';
import { Paragraph } from 'src/shared/ui/Paragraph';
import { Heading } from 'src/shared/ui/Heading';
import { SUB } from 'src/app/consts';

export const Welcome: React.FC<DivProps> = props => {
	const {
		className,
		...divProps
	} = props;

	// * Actions
	const dispatch = useAppDispatch();
	const {getCompanies} = actionsUserInfo;

	// * Initialization
	useEffect(() => {
		dispatch(getCompanies());
	}, []);

	// * Selector
	const companiesInfo = useAppSelector(state => state.userInfo.companiesInfo);

	// * Render
	return (
		<div {...divProps} className={cn(s.container, className)}>
			<div className={s.inner}>
				<div className={s.inner__left}>
					<Heading
						level={1}
						color="light"
						marginBottom="m"
					>
						Denominator<sup>{SUB}</sup>
					</Heading>

					<Paragraph color="light">
						Welcome to <DenominatorSubLogo color="light"/>, here you will be guided through a short data collection
						survey about your company that will help Denominator increase data coverage and provide more accurate
						rating. You can also download a pdf certificate and a widget for your website or email signature, showing a
						certified rating for your company.
						Feel free to explore the rest of Denominator offering, using the links below.
					</Paragraph>
				</div>

				{companiesInfo && (
					<div className={s.inner__right}>
						<Heading
							data-test={'company_card_my_companies'}
							level={3}
							color="light"
							marginBottom="m"
						>
							My companies
						</Heading>

						{companiesInfo.advancedCompanies.map(companyInfo => (
							<CompanyCard
								key={companyInfo.id}
								companyId={companyInfo.id}
								companyName={companyInfo.name}
								score={companyInfo.rating} // ! Company rating returned from the backend is actually a score.
								absoluteRating={companyInfo.absoluteRating}
								relativeRating={companyInfo.relativeRating}
								ratingType="advanced" // ! only advanced is relevant.
							/>
						))}
					</div>
				)}
			</div>
		</div>
	);
};
