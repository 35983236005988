import React, { ReactNode } from 'react';
import s from 'src/shared/ui/Icon/ui/Icon.module.scss';
import { DivProps } from 'src/shared/types/props';
import cn from 'classnames';

interface Props extends DivProps {
	svg: ReactNode; // TODO: Type SVG.
	size?: number;
	color?: string;
}

export const Icon: React.FC<Props> = props => {

	const {
		svg,
		color = '#00356B',
		size,
		onClick,
		className,
		...divProps
	} = props;

	return (
		<div
			{...divProps}
			className={cn(s.container, className)}
			onClick={onClick}
			style={{
				cursor: onClick ? 'pointer' : 'inherit',
				fill: color,
				width: `${size}rem`,
				minWidth: `${size}rem`,
				maxWidth: `${size}rem`,
				height: `${size}rem`,
				minHeight: `${size}rem`,
				maxHeight: `${size}rem`
			}}
		>
			{svg}
		</div>
	);
};
