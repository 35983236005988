import React from 'react';
import s from './Header.module.scss';
import { useNavigate } from 'react-router-dom';
import { HeaderProfile } from 'src/entities/Header/ui/HeaderProfile/HeaderProfile';
import { Logo } from 'src/shared/ui/Logo';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/BLL/rootReducer';
import { RouterPath } from 'src/pages/app/router/AppRouter';
import { HtmlProps } from 'src/shared/types/props';
import cn from 'classnames';

interface Props extends HtmlProps {
}

export const Header: React.FC<Props> = props => {

	const {
		className,
		...htmlProps
	} = props;

	const navigate = useNavigate();

	// * Selectors
	const {userData} = useSelector((state: RootState) => state.auth);

	// * Functions
	const onLogoClick = () => navigate(RouterPath.mainPage);

	// * Component
	return (
		<header {...htmlProps} className={cn(s.container, className)}>
			<div className={s.left}>
				<Logo className={s.logo} onClick={onLogoClick}/>
			</div>

			{userData && (
				<HeaderProfile
					userData={userData}
				/>
			)}
		</header>
	);
};
