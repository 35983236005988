import React, { memo, useEffect } from 'react';
import { useAppDispatch } from 'src/app/BLL/createAction';
import { DashBoardMenuName, MetaTab, PropertyType } from '../_BLL/types';
import { RatingType } from '../../MainPage/_BLL/types';
import { actionsCompany } from 'src/pages/company/_BLL/slice';

interface Props {
	formValues: { name: string, data: PropertyType }[];
	tabMeta: MetaTab;
	ratingType: RatingType;
}

// TODO: Figure out if this component needs memo.
export const ProgressCalcCompany: React.FC<Props> = memo(props => {

	const {
		formValues,
		tabMeta,
		ratingType
	} = props;

	// * Actions
	const dispatch = useAppDispatch();
	const {storeProgress} = actionsCompany;

	const handleStoreProgress = (payload: { tabName: DashBoardMenuName, progressValue: number, ratingType: RatingType }) => dispatch(storeProgress(payload));

	const progress = calculateProgress(formValues, tabMeta);

	useEffect(() => {
		handleStoreProgress({
			tabName: tabMeta.name,
			progressValue: progress,
			ratingType
		});
	}, [progress, tabMeta]);

	// ! Return no component
	return null;
});

// * Functions
export const calculateProgress = (formValues: { name: string, data: PropertyType }[], tabMeta: MetaTab) => {

	const tabProperties = tabMeta.groups.map(group => group.properties).flat();

	// Array of current tab property names.
	const currentTabPropertiesNames = tabProperties.map(property => property.propertyName);

	// Array of current tab properties with data.
	const currentTabPropertiesData = formValues.filter(property => (
		currentTabPropertiesNames.includes(property.name)
	));

	const values = currentTabPropertiesData
		.map(property => {
			if ('dataPoint' in property.data) {
				if ('value' in property.data.dataPoint) {
					if (property.data.dataPoint.value) return true;
					else return null;
				}
				if ('values' in property.data.dataPoint) {
					if (property.data.dataPoint.values && property.data.dataPoint.values.length > 0) return true;
					else return null;
				}
			} else if ('existence' in property.data) {
				if ('value' in property.data.existence) {
					if (property.data.existence.value) return true;
					else return null;
				}
			} else if ('sector' in property.data) {
				if ('values' in property.data.sector) {
					if (property.data.sector.values && property.data.sector.values.length > 0) return true;
					else return null;
				}
			} else if ('delta' in property.data) {
				if ('value' in property.data.delta) {
					if (property.data.delta.value) return true;
					else return null;
				}
			} else return null;
		}).flat();

	return values.filter((value: any) => value !== null && value !== '').length;
};

