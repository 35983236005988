import { combineReducers } from 'redux';
import { slice as auth } from '../../pages/auth/_BLL/slice';
import { slice as notifications } from '../../pages/app/_BLL/notifications/slice';
import { slice as metaDictionaries } from '../../pages/app/_BLL/metaDictionaries/slice';
import { slice as userInfo } from 'src/pages/MainPage/_BLL/slice';
import { slice as company } from 'src/pages/company/_BLL/slice';

export const rootReducer = combineReducers({
	auth: auth.reducer,
	notifications: notifications.reducer,
	metaDictionaries: metaDictionaries.reducer,
	// app,
	// auth,
	userInfo: userInfo.reducer,
	company: company.reducer
});

export type RootState = ReturnType<typeof rootReducer>
