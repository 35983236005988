import React from 'react';
import s from './ValueDictionaryTooltip.module.scss';
import { Tooltip } from '@mui/material';
import { Icon } from 'src/shared/ui/Icon';
import DotsSVG from 'src/shared/assets/svg/dots_in_circle.svg';
import { FieldDictionary } from 'src/pages/company/_BLL/types';
import { dictionaryIdToString } from 'src/helpers/dataHelpers';

interface Props {
	title: string;
	fieldDictionary: FieldDictionary;
}

export const ValueDictionaryToolTip: React.FC<Props> = props => {
	const { title, fieldDictionary } = props;

	return (
		<Tooltip
			title={dictionaryIdToString(title, fieldDictionary)}
			placement="right-start"
		>
			<div className={s.inner}>
				<Icon svg={<DotsSVG />} />
			</div>
		</Tooltip>
	);
};
