import React, { memo } from 'react';
import s from './ButtonSquared.module.scss';
import { Preloader } from 'src/shared/ui/loaders/Preloader';
import { Button, ButtonProps } from '@mui/material';

interface Props extends ButtonProps {
	isLoading?: boolean;
}

export const ButtonSquared: React.FC<Props> = memo(props => {

	const {
		isLoading = false,
		disabled,
		children,
		...buttonProps
	} = props;

	const renderLoader = () => {
		return isLoading ? <Preloader style={{width: '1rem', height: '1rem'}}/> : '';
	};

	// * Render
	return (
		<Button
			{...buttonProps}
			disabled={disabled || isLoading}
			variant="contained"
			color="primary"
		>
			<span className={s.inner}>
				{children}
				{renderLoader()}
			</span>
		</Button>
	);
});
