import React, { memo, useState } from 'react';
import s from 'src/entities/Menu/ui/Nav/Nav.module.scss';
import { NavItem, SetActiveMenuItem } from 'src/entities/Menu/ui/NavItem/NavItem';
import MenuSVG from 'src/shared/assets/svg/menu.svg';
import { DashBoardMenuName, SingleMenuItem } from 'src/pages/company/_BLL/types';
import { Icon } from 'src/shared/ui/Icon';

interface Props {
	menuItems: SingleMenuItem[] | null;
	activeMenuItem: DashBoardMenuName;
	onMenuClick: SetActiveMenuItem;
}

export const Nav: React.FC<Props> = memo(props => {
	const {
		menuItems, //
		activeMenuItem,
		onMenuClick,
	} = props;

	const [menuOpen, setMenuOpen] = useState(true);

	const checkIfActive = (menuItemName: DashBoardMenuName, activeMenuItem: DashBoardMenuName) => menuItemName === activeMenuItem;

	// * Render
	return (
		<div className={s.container}>
			<Icon
				className={s.icon}
				svg={<MenuSVG />}
				size={1}
				onClick={() => setMenuOpen(!menuOpen)}
			/>

			<div className={s.menuItems}>
				{menuOpen &&
					menuItems &&
					menuItems.map((item, index) => (
						<NavItem
							data-test={'menu_item_' + index.toString()}
							key={index}
							index={index}
							name={item.name}
							value={item.value}
							totalValue={item.totalValue}
							activeMenuItem={checkIfActive(item.name, activeMenuItem)}
							onClick={onMenuClick}
						/>
					))}
			</div>
		</div>
	);
});
