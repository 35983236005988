import React from 'react';
import s from './ButtonsWrapper.module.scss';
import { DivProps } from 'src/shared/types/props';
import cn from 'classnames';

export const ButtonsWrapper: React.FC<DivProps> = props => {
	const {
		children,
		className,
		...divProps
	} = props;

	// * Render
	return (
		<div {...divProps} className={cn(s.container, className)}>
			{children}
		</div>
	);
};
