import React from 'react';
import s from './Paragraph.module.scss';
import { ColorProp, ParagraphProps, SizeProp } from 'src/shared/types/props';
import cn from 'classnames';
import { getMarginBottomClass } from 'src/shared/lib/style';

interface Props extends ParagraphProps {
	color?: ColorProp;
	marginBottom?: SizeProp;
}

export const Paragraph: React.FC<Props> = props => {
	const {
		color = 'dark',
		marginBottom = 'm',
		className,
		children,
		...paragraphProps
	} = props;

	// * Render
	return (
		<p {...paragraphProps} className={cn(s[`container_${color}`], getMarginBottomClass(marginBottom), className)}>
			{children}
		</p>
	);
};
