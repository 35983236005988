import cn from 'classnames';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SingleInfo } from 'src/entities/SingleInfo';
import { RouterPath } from 'src/pages/app/router/AppRouter';
import { RatingType } from 'src/pages/MainPage/_BLL/types';
import CompanySvg from 'src/shared/assets/svg/illustrations/undraw_survey_05s5.svg';
import { DivProps } from 'src/shared/types/props';
import { ButtonSquared } from 'src/shared/ui/buttons/ButtonSquared';
import { Modal } from 'src/shared/ui/Modal/Modal';
import { ButtonsWrapper } from 'src/shared/ui/wrappers/ButtonsWrapper';
import { CertificatesMC } from 'src/widgets/CertificatesMC';
import s from './CompanyCard.module.scss';

interface Props extends DivProps {
	companyId: number;
	companyName: string;
	score: number;
	absoluteRating: string;
	relativeRating: string;
	ratingType: RatingType;
}

export const CompanyCard: React.FC<Props> = props => {
	const {
		companyId, //
		companyName,
		score,
		absoluteRating,
		relativeRating,
		ratingType,
		className,
		...divProps
	} = props;

	// * Router
	const navigate = useNavigate();

	const openEditCompany = () => navigate(RouterPath.companyPage + `/${ratingType}/${companyId}`);

	const [certificatesModal, setCertificatesModal] = useState(false);
	const openCertificatesModal = () => setCertificatesModal(true);
	const closeCertificatesModal = () => setCertificatesModal(false);

	// * Render
	return (
		<>
			<Modal
				isOpen={certificatesModal}
				onClose={closeCertificatesModal}
			>
				<CertificatesMC
					companyId={companyId}
					companyName={companyName}
					score={score}
					absoluteRating={absoluteRating}
					relativeRating={relativeRating}
				/>
			</Modal>

			<div
				{...divProps}
				className={cn(s.container, className)}
			>
				<div>
					<div className={s.info}>
						<SingleInfo
							data-test={'company_card_dei_id'}
							caption="DEI Id"
							info={companyId}
						/>

						<SingleInfo
							data-test={'company_card_company_name'}
							caption="Name"
							info={companyName}
						/>
					</div>

					<ButtonsWrapper>
						<ButtonSquared
							data-test={'company_card_edit_button'}
							onClick={openEditCompany}
						>
							Edit company
						</ButtonSquared>

						<ButtonSquared
							data-test={'company_card_certs_button'}
							onClick={openCertificatesModal}
						>
							Certificates
						</ButtonSquared>
					</ButtonsWrapper>
				</div>

				<CompanySvg className={s.img} />
			</div>
		</>
	);
};
