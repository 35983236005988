import { Dictionaries } from 'src/pages/app/_BLL/metaDictionaries/types';

type RatingType = 'advanced' | 'certified';

// * Menu
// TODO: Convert to keys of meta... Menu names are based on meta tab names.
export type DashBoardMenuName =
	| 'Welcome' // ? Not a form menu name
	| 'General'
	| 'Diversity by Ownership'
	| 'Gender'
	| 'Race'
	| 'Age'
	| 'DEI'
	| 'Inclusion'
	| 'Disability'
	| 'Education'
	| 'Family'
	| 'Health'
	| 'Job'
	| 'Nationality'
	| 'Organizational'
	| 'Religion'
	| 'Sexuality'
	| 'Submit for rating'; // ? Not a form menu name

export interface SingleMenuItem {
	name: DashBoardMenuName;
	value: number | null;
	totalValue: number | null;
}

// * Meta data
export interface MetaEntity {
	entityName: DashboardMetaEntityName;
	tabs: MetaTab[];
}

export type DashboardMetaEntityName = 'company' | 'person';

export interface MetaTab {
	name: DashBoardMenuName;
	groups: MetaGroup[];
	properties?: PropertyMeta[];
}

export interface MetaGroup {
	name: MetaGroupNames;
	properties: PropertyMeta[];
}

export type MetaGroupNames =
	| 'General information'
	| 'Ownership'
	| 'Operations'
	| 'Organizational'
	| 'Diversity, Equity & Inclusion (DEI)'
	| 'Gender'
	| 'Age'
	| 'Nationality'
	| 'Urban'
	| 'Race/Ethnicity'
	| 'Disability'
	| 'Health'
	| 'Education'
	| 'Family'
	| 'Religion'
	| 'Sexuality'
	| 'Political'
	| 'Inclusion'
	| 'Job'
	| 'Board Profile'
	| 'Executive Profile';

export interface PropertyMeta {
	propertyName: string;
	displayName: string;
	fieldOrder: number;
	showInHeader: boolean;
	handlingType: PropertyHandlingType;
	description?: string;
	dataPoints: DataPointMeta[];
}

export type PropertyHandlingType = 'normal' | 'sectorIndustry' | 'target' | 'deltaPercentage';

export interface DataPointMeta {
	internalName: DataPointName;
	fieldType: FieldType;
	isEditable: boolean;
	allowsNull: boolean;
	allowsMany: boolean;
	measureUnits?: MeasureUnits;
	rangeLow?: number;
	rangeHigh?: number;
	options?: string[];
	dictionary?: keyof Dictionaries;
}

export type DataPointName = 'dataPoint' | DataPointNameTarget | DataPointNameSectorIndustry | DataPointNameDeltaPercentage;

export type DataPointNameTarget = 'existence' | 'target' | 'deadline';

export type DataPointNameSectorIndustry = 'sector' | 'industry';

export type DataPointNameDeltaPercentage = 'delta' | 'Percentage';

export type FieldType =
	| 'Integer'
	| 'Id'
	| 'IntegerWithDeadline'
	| 'Float'
	| 'FloatWithDeadline'
	| 'String'
	| 'Boolean'
	| 'BooleanDropDown'
	| 'BooleanWithDeadline'
	| 'Percentage'
	| 'PercentageWithDeadline'
	| 'DropDown'
	| 'DictionaryArray'
	| 'Date'
	| 'YearAge'
	| 'AgeFloat';

export type MeasureUnits = '%' | 'weeks' | 'h' | 'year' | 'cm' | 'kg';

// * Fields
export interface GeneratedField {
	namePrefix: string;
	propertyName: string;
	internalName: string; // TODO: type internalName
	validate?: ValidateFunction;
	measureUnits?: MeasureUnits;
	fieldWidth?: string;
	handlingType?: PropertyHandlingType;
	fieldDictionary?: FieldDictionary;
}

export type FieldDictionary = Dictionaries[keyof Dictionaries];
export type ValidateFunction = (value: string) => undefined | string;

// * Company
export interface Company {
	accessType: AccessType;
	id: number;
	entityName: 'company';
	displayName: string;
	properties: PropertiesCompany;
}

export type AccessType =
	| 1 // Advanced
	| 2; // Certified

export interface PropertiesCompany {
	// General properties response type.
	[key: string]: CommonProperty | IndustryAndSectorProperty | TargetProperty | DeltaProperty;

	// Hardcoded properties. Meant to reduce type checking in components.
	legalName: CommonProperty;
	locationCountry: CommonProperty;

	industryAndSector: IndustryAndSectorProperty;

	targetBoardVisibleMinority: TargetProperty;
	targetExecutivesVisibleMinority: TargetProperty;
	targetSeniorVisibleMinority: TargetProperty;
	targetMiddleVisibleMinority: TargetProperty;
	targetAllVisibleMinority: TargetProperty;
	targetBoardFemalePercentage: TargetProperty;
	targetExecutiveFemalePercentage: TargetProperty;
	targetSeniorFemalePercentage: TargetProperty;
	targetMiddleFemalePercentage: TargetProperty;
	targetAllFemalePercentage: TargetProperty;
	targetBoardAverageAge: TargetProperty;
	targetExecutiveAverageAge: TargetProperty;
	targetSeniorAverageAge: TargetProperty;
	targetMiddleAverageAge: TargetProperty;
	targetAllAverageAge: TargetProperty;
}

export type PropertyType = CommonProperty | IndustryAndSectorProperty | TargetProperty | DeltaProperty;

export interface CommonProperty {
	dataPoint: DataPoint;
}

export interface IndustryAndSectorProperty {
	industry: DataPoint;
	sector: DataPoint;
}

export interface TargetProperty {
	existence: DataPoint;
	target: DataPoint;
	deadline: DataPoint;
}

export interface DeltaProperty {
	delta: DataPoint;
	percentage: DataPoint;
}

export enum StatusType {
	FILLED = 'Filled',
	EMPTY = 'Empty',
	UNKNOWN = 'Unknown',
}

export interface DataPoint {
	value?: DataPointValue;
	values?: DataPointValues;
	type: string;
	source: string | null;
	proofUrl: string | null; // Link to proof image, received from request.
	locationInProof: string | null; // Extra information on proof. Example: Page number
	proofContent: ProofContent; // Always null when the people are received.
	status: StatusType;
}

export interface ProofContent {
	content: string | null; // Pure base64 without data and MIME types
	constType: string | null; //MIME type
}

export type DataPointValue = string | null;
export type DataPointValues = string[] | null;
