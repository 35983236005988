import { localStorageName } from '../BLL/localStorage';
import { RootState } from '../BLL/rootReducer';
import { handleResponse } from 'src/app/api/shared';
import { State } from 'src/pages/auth/_BLL/slice';

// Prod - jbkgxnjdke
// Dev - y7mle7cxhb
const host = process.env.REACT_APP_HOST || 'Error... in building REACT_APP_HOST';

export class ResponseError extends Error {
	severity: ErrorSeverity;
	incidentId?: string;

	constructor(message: any, severity: ErrorSeverity, incidentId?: string, ...params: any) {
		super(...params);
		this.severity = severity;
		this.message = message;
		this.incidentId = incidentId;
	}
}

const getAuth = (): State | null => {
	const state = localStorage.getItem(localStorageName);
	const parsed = state ? (JSON.parse(state) as RootState) : null;
	return parsed ? parsed.auth : null;
};

const getHeaders = (state: State | null, token?: string) => {
	const apiToken = token || (state && state.userData ? state.userData.token : '');

	return {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Request-Headers': '*',
		'x-api-token': apiToken,
	};
};

// * RTK api
export const getRequestUrl = (url: string, params?: string) => {
	return `${host}/${url}${params ? '?' + params : ''}`;
};

export const generateParams = (params?: object) => {
	return params
		? Object.entries(params)
			.map(([key, value]) => `${key}=${value}`)
			.join('&')
		: '';
};

// * RTK pure requests
interface FetchGET {
	url: string;
	params?: object;
	signal?: AbortSignal;
	token?: string;
}

const GET = async (args: FetchGET) => {
	const { url, params, signal, token } = args;

	const auth = getAuth();

	return await fetch(getRequestUrl(url, generateParams(params)), {
		method: 'GET',
		headers: getHeaders(auth, token),
		referrerPolicy: 'no-referrer',
		signal,
	});
};

interface FetchPOST {
	url: string;
	params?: object;
	payload?: object;
	signal?: AbortSignal;
}

const POST = async (args: FetchPOST) => {
	const { url, params, payload, signal } = args;

	const auth = getAuth();

	return await fetch(getRequestUrl(url, generateParams(params)), {
		method: 'POST',
		mode: 'cors',
		headers: getHeaders(auth),
		referrerPolicy: 'no-referrer',
		body: JSON.stringify(payload),
		signal,
	});
};

interface FetchPUT {
	url: string;
	params?: object;
	payload?: object;
	signal?: AbortSignal;
}

export const PUT = async (args: FetchPUT) => {
	const { url, params, payload, signal } = args;

	const auth = getAuth();

	return await fetch(getRequestUrl(url, generateParams(params)), {
		method: 'PUT',
		mode: 'cors',
		headers: getHeaders(auth),
		referrerPolicy: 'no-referrer',
		body: JSON.stringify(payload),
		signal,
	});
};

interface FetchDEL {
	url: string;
	params?: object;
	payload?: object;
	signal?: AbortSignal;
}

const DEL = async (args: FetchDEL) => {
	const { url, params, payload, signal } = args;

	const auth = getAuth();

	return await fetch(getRequestUrl(url, generateParams(params)), {
		method: 'DELETE',
		mode: 'cors',
		headers: getHeaders(auth),
		referrerPolicy: 'no-referrer',
		body: JSON.stringify(payload),
		signal,
	});
};

// * RTK request handlers
const getRequest = async <RES>(args: FetchGET & { thunkAPI: any }) => {
	const { thunkAPI, ...getArgs } = args;

	const response = await GET(getArgs);

	return await handleResponse<RES>({
		response,
		thunkAPI,
	});
};

const postRequest = async <RES>(args: FetchPOST & { thunkAPI: any }) => {
	const { thunkAPI, ...postArgs } = args;

	const response = await POST(postArgs);

	return await handleResponse<RES>({
		response,
		thunkAPI,
	});
};

const putRequest = async <RES>(args: FetchPUT & { thunkAPI: any }) => {
	const { thunkAPI, ...putArgs } = args;

	const response = await PUT(putArgs);

	return await handleResponse<RES>({
		response,
		thunkAPI,
	});
};

const delRequest = async <RES>(args: FetchDEL & { thunkAPI: any }) => {
	const { thunkAPI, ...delArgs } = args;

	const response = await DEL(delArgs);

	return await handleResponse<RES>({
		response,
		thunkAPI,
	});
};

// * Export
export const apiRequest = {
	getRequest,
	postRequest,
	putRequest,
	delRequest,
};

// * Types
export type ErrorSeverity = 'Information' | 'Warning' | 'Error' | 'AccessDenied' | 'Critical';
