import React from 'react';
import s from './Heading.module.scss';
import { ColorProp, HeadingProps, SizeProp } from 'src/shared/types/props';
import cn from 'classnames';
import { getMarginBottomClass } from 'src/shared/lib/style';

type HeadingLevels = 1 | 2 | 3 | 4 | 5 | 6;

interface Props extends HeadingProps {
	level: HeadingLevels;
	marginBottom?: SizeProp;
	color?: ColorProp;
	size?: HeadingLevels;
}

export const Heading: React.FC<Props> = props => {
	const {
		level,
		color = 'dark',
		marginBottom,
		size = level,
		className,
		children,
		...headingProps
	} = props;

	const componentProps = {
		className: cn(
			s.container,
			s[`container_${color}`],
			s[`container_size_${size}`],
			getMarginBottomClass(marginBottom),
			className
		),
		color,
		...headingProps
	};

	const renderHeading = (level: HeadingLevels) => {
		switch (level) {
			case 1:
				return <h1 {...componentProps}>{children}</h1>;
			case 2:
				return <h2 {...componentProps}>{children}</h2>;
			case 3:
				return <h3 {...componentProps}>{children}</h3>;
			case 4:
				return <h4 {...componentProps}>{children}</h4>;
			case 5:
				return <h5 {...componentProps}>{children}</h5>;
			case 6:
				return <h6 {...componentProps}>{children}</h6>;
		}
	};

	// * Render
	return (<>
		{renderHeading(level)}
	</>);
};
