import React from 'react';
import s from './DenominatorCooperLogo.module.scss';
import cn from 'classnames';
import { ColorProp, SpanProps } from 'src/shared/types/props';

interface Props extends SpanProps {
	size?: 'title' | 'paragraph';
	color?: ColorProp
	sub?: string
}

export const DenominatorSubLogo: React.FC<Props> = props => {
	const {
		size = 'paragraph',
		color = 'dark',
		sub = 'Company',
		className,
		...spanProps
	} = props;

	const containerStyle = cn(
		s.container,
		s[`container_${size}`],
		s[`container_${color}`],
		className
	)

	// * Render
	return (
		<span {...spanProps} className={containerStyle}>
			Denominator<sup>{sub}</sup>
		</span>
	);
};
