import {Company} from '../pages/company/_BLL/types';

export const preventSubmitOnEnter = (event: any) => {
	if ((event.key) === 'Enter') {
		event.preventDefault();
	}
};

export const fileToBase64 = (file: any, setFunction: any) => {
	const reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = function () {
		setFunction({
			content: reader.result && typeof reader.result === 'string' && reader.result.split(',')[1],
			contentType: file.type
		});
	};
};

export const setAllSourcesToProprietary = (company: Company) => {

	const companyProperties = {...company.properties};

	for (const [propertyKey, dataPoints] of Object.entries(companyProperties)) {
		for (const [dataPointKey, value] of Object.entries(dataPoints)) {

			// @ts-ignore
			companyProperties[propertyKey][dataPointKey] = {
				...value,
				source: 4
			};
		}
	}

	const companyWithSources = {
		...company,
		properties: {...companyProperties}
	};

	return companyWithSources as Company;
};
