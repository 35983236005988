import React, { ChangeEvent } from 'react';
import s from './DeadlineField.module.scss';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { InputAdornment, TextField } from '@mui/material';
import { GeneratedField } from 'src/pages/company/_BLL/types';

type Props = Omit<GeneratedField, 'validate' | 'measureUnits'>

export const DeadlineField: React.FC<Props> = props => {

	const {
		namePrefix,
		propertyName,
		internalName,
	} = props;

	// * Form
	const {setValue, control, trigger} = useFormContext();

	const existence = useWatch({
		name: `${namePrefix}${propertyName}.existence.value`
	});

	const name = `${namePrefix}${propertyName}.${internalName}`;

	const deadlineValidation = (value: string | null) => {

		if (value !== null) {
			const formattedValue = value.slice(-2) === 'AM' || value.slice(-2) === 'PM' ? changeValueFormat(value) : value;

			if (formattedValue === 'NaN-NaN-NaN') {
				return 'Year must be between 1900 and 2100';
			}
			if (+formattedValue.slice(0, formattedValue.length - 6) < 1900 || +formattedValue.slice(0, formattedValue.length - 6) > 2100) {
				return 'Year must be between 1900 and 2100';
			}
		}
	};

	const textFieldOnChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (event.currentTarget.value === '') {
			setValue(`${name}.value`, null);
		} else {
			setValue(`${name}.value`, `${event.currentTarget.value}-01-01`);
		}
	};

	const fakeValue = (value: string | null) => {
		const formattedValue = value !== null && (value.slice(-2) === 'AM' || value.slice(-2) === 'PM') ? changeValueFormat(value) : value;

		if (formattedValue !== null) {
			return formattedValue.slice(0, formattedValue.length - 6);
		} else return '';
	};

	const addZeroToMonth = (date: number) => {
		const dateString = date.toString();

		if (dateString.length === 1) {
			return '0' + dateString;
		} else return dateString;
	};

	const addZeroToYear = (date: number) => {
		const dateString = date.toString();

		if (dateString.length === 1) {
			return '000' + dateString;

		} else if (dateString.length === 2) {
			return '00' + dateString;

		} else if (dateString.length === 3) {
			return '0' + dateString;

		} else return dateString;
	};

	const changeValueFormat = (value: string | null) => {
		if (value === null) return '';

		const date = new Date(value);

		return `${addZeroToYear(date.getFullYear())}-${addZeroToMonth(date.getUTCMonth() + 1)}-${addZeroToMonth(date.getDate())}`;
	};

	// Target Fields only ...
	const disableBasedOnExistence = existence === null || existence === 'false';
	// ...

	// * Component
	return (
		<Controller
			name={`${name}.value`}
			control={control}
			rules={{validate: deadlineValidation}}
			render={({field, fieldState}) => (
				<div className={s.container}>
					<div className={s.fieldWrapper}>
						<span className={s.additionalTextLeft}>Deadline:</span>
						<TextField
							{...field}
							className={s.textField}
							value={fakeValue(field.value)}
							onChange={(event) => {
								textFieldOnChangeHandler(event);
							}}
							onBlur={() => trigger()}
							disabled={disableBasedOnExistence}
							size="small"
							InputProps={{
								endAdornment: <InputAdornment position="end">year</InputAdornment>
							}}
						/>

					</div>

					{fieldState.error && <span className={s.error}>{fieldState.error.message}</span>}
				</div>
			)}
		/>
	);
};
