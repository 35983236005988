import {css, styled} from '@mui/material/styles';

const Group = styled('div')(({theme}) => css`
  flex-grow: 1;

  padding: 2rem 2rem 1rem;

  background-color: ${theme.palette.common.white};

  border: 1px solid ${theme.palette.custom.blueBorder};
  border-radius: ${theme.shape.borderRadius + 'px'};
`);

const Order = styled('span')(({theme}) => css`
  margin-right: .5rem;

  width: 3rem;

  color: ${theme.palette.primary.main};
`);

const GroupTitle = styled('div')`
  display: flex;

  margin-bottom: 1rem;

  & > button {
    margin-left: 1rem;
  }
`;

export const DashboardStyles = {
	Group,
	Order,
	GroupTitle,
};
