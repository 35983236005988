import React, { ReactNode } from 'react';
import s from './ServiceCard.module.scss';
import { ButtonSquared } from 'src/shared/ui/buttons/ButtonSquared';
import { Paragraph } from 'src/shared/ui/Paragraph';
import { Heading } from 'src/shared/ui/Heading';
import { DivProps } from 'src/shared/types/props';
import cn from 'classnames';

interface Props extends DivProps {
	Title: ReactNode | string;
	Description: ReactNode | string;
	SVG: ReactNode;
	button: {
		text: string;
		onClick: () => void;
	};
}

export const ServiceCard: React.FC<Props> = props => {
	const {
		Title, //
		Description,
		SVG,
		button,
		className,
		...divProps
	} = props;

	const { text, onClick } = button;

	// * Render
	return (
		<div
			{...divProps}
			className={cn(s.container, className)}
		>
			<div>
				<Heading
					level={3}
					marginBottom="m"
				>
					{Title}
				</Heading>

				<Paragraph marginBottom="m">{Description}</Paragraph>
			</div>

			<div className={s.footer}>
				<ButtonSquared onClick={onClick}>{text}</ButtonSquared>

				<div className={s.img}>{SVG}</div>
			</div>
		</div>
	);
};
