import { Company, DashBoardMenuName, MetaEntity, SingleMenuItem } from 'src/pages/company/_BLL/types';
import { RequestStatus, UrlAPI } from 'src/app/api/types';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiRequest } from 'src/app/api/api';
import { RatingType } from 'src/pages/MainPage/_BLL/types';
import { setAllSourcesToProprietary } from 'src/helpers/formHelpers';
import { actionsNotifications } from 'src/pages/app/_BLL/notifications/slice';

const NAME = 'company';

const getMeta = createAsyncThunk(`${NAME}/getMeta`, async (payload: { ratingType: RatingType }, thunkAPI) => {
	const {ratingType} = payload;

	const generateMenuItems = (meta: MetaEntity): SingleMenuItem[] => {
		const formMenuItems = meta.tabs.map(tab => ({
			name: tab.name,
			value: 0,
			totalValue: tab.groups[0].properties.length // ? There is only one group in each tab.
		}));

		return [{
			name: 'Welcome',
			value: null,
			totalValue: null
		}, ...formMenuItems, {
			name: 'Submit for rating',
			value: null,
			totalValue: null
		}];
	};

	const res = await apiRequest.getRequest<MetaEntity>({
		url: ratingType === 'advanced' ? UrlAPI.advancedMeta : UrlAPI.certifiedMeta,
		thunkAPI
	});

	return ({
		ratingType,
		menuItems: generateMenuItems(res),
		meta: res
	});
});

const getCompany = createAsyncThunk(`${NAME}/getCompany`, async (payload: { ratingType: RatingType, id: string }, thunkAPI) => {
	const {id, ratingType} = payload;

	const accessType = ratingType === 'advanced' ? '1' : '2';

	const res = await apiRequest.getRequest<Company>({
		url: UrlAPI.company,
		params: {
			id,
			access_type: accessType
		},
		thunkAPI
	});

	return setAllSourcesToProprietary(res);
});

const updateCompany = createAsyncThunk(`${NAME}/updateCompany`, async (payload: Company, thunkAPI) => {
	const {dispatch} = thunkAPI;

	const res = await apiRequest.postRequest<Company>({
		url: UrlAPI.company,
		payload,
		thunkAPI
	});

	dispatch(actionsNotifications.addNotification({
		type: 'success',
		message: 'Company rating submitted'
	}));

	return res;
});

// * Reducer
interface State {
	meta: {
		advanced: MetaEntity | null
		certified: MetaEntity | null
	};
	company: Company | null;
	menuItems: {
		advanced: SingleMenuItem[] | null
		certified: SingleMenuItem[] | null
	};
	activeMenu: DashBoardMenuName;
	status: RequestStatus;
}

export const initialState: State = {
	meta: {
		advanced: null,
		certified: null
	},
	company: null,
	menuItems: {
		advanced: null,
		certified: null
	},
	activeMenu: 'Welcome' as const,
	status: RequestStatus.still
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setActiveMenuOption: (state, action: PayloadAction<DashBoardMenuName>) => {
			state.activeMenu = action.payload;
		},
		storeProgress: (state, action: PayloadAction<{ tabName: DashBoardMenuName, progressValue: number, ratingType: RatingType }>) => {
			const {ratingType, progressValue, tabName} = action.payload;

			const newMenuItems = state.menuItems[ratingType]?.map(item => {

				if (tabName === item.name) {
					return {
						name: item.name,
						value: progressValue,
						totalValue: item.totalValue,
					};
				}

				return item;
			});

			return {
				...state,
				menuItems: {
					...state.menuItems,
					[ratingType]: newMenuItems
				}
			};
		}
	},
	extraReducers: builder => {
		builder.addCase(getMeta.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getMeta.fulfilled, (state, action) => {
			state.menuItems[action.payload.ratingType] = action.payload.menuItems;
			state.meta[action.payload.ratingType] = action.payload.meta;
			state.status = RequestStatus.still;
		});
		builder.addCase(getMeta.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(getCompany.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getCompany.fulfilled, (state, action) => {
			state.company = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(getCompany.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(updateCompany.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(updateCompany.fulfilled, (state, action) => {
			state.company = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(updateCompany.rejected, state => {
			state.status = RequestStatus.failed;
		});
	}
});

export const actionsCompany = {
	...slice.actions,
	getMeta,
	getCompany,
	updateCompany
};
