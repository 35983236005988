import { FormControlLabel, Radio } from '@mui/material';
import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { GeneratedField, StatusType } from 'src/pages/company/_BLL/types';
import s from './BooleanField.module.scss';
import { useOwned } from './hooks/useOwned';

interface Props extends GeneratedField {
	options?: string[];
}

export const BooleanField: React.FC<Props> = memo(props => {
	const {
		namePrefix, //
		propertyName,
		internalName,
		validate,
		options,
	} = props;

	// * Form
	const { setValue, getValues, control } = useFormContext();
	const formValues = getValues();

	const name = `${namePrefix}${propertyName}.${internalName}`;

	// * Diversity by Ownership logic
	const { handleOwnedTrue, handleOwnedFalse, disabled } = useOwned(propertyName);

	// * Functions
	const labelExceptions = (value: 'true' | 'false') => {
		if (options) {
			return value === 'true' ? options[1] : options[0];
		}
		return value === 'true' ? 'Yes' : 'No';
	};

	const onChangeTrue = () => {
		setValue(`${name}.value`, 'true');
		setValue(`${name}.status`, StatusType.FILLED);

		if ('target' in formValues.properties[propertyName]) {
			setValue(`properties.${propertyName}.target.status`, StatusType.FILLED);
		}

		if ('deadline' in formValues.properties[propertyName]) {
			setValue(`properties.${propertyName}.deadline.status`, StatusType.FILLED);
		}

		handleOwnedTrue(); // Diversity by Ownership
	};

	const onChangeFalse = () => {
		setValue(`${name}.value`, 'false');
		setValue(`${name}.status`, StatusType.FILLED);

		if ('target' in formValues.properties[propertyName]) {
			setValue(`properties.${propertyName}.target.value`, null);
			setValue(`properties.${propertyName}.target.status`, StatusType.EMPTY);
		}

		if ('deadline' in formValues.properties[propertyName]) {
			setValue(`properties.${propertyName}.deadline.value`, null);
			setValue(`properties.${propertyName}.deadline.status`, StatusType.EMPTY);
		}

		handleOwnedFalse(); // Diversity by Ownership
	};

	// * Render
	return (
		<Controller
			name={`${name}.value`}
			control={control}
			rules={{ validate: validate }}
			render={({ field }) => (
				<div className={s.fieldWrapper}>
					<FormControlLabel
						{...field}
						label={labelExceptions('true')}
						checked={field.value === 'true'}
						onChange={onChangeTrue}
						control={<Radio color="primary" />}
						disabled={disabled}
					/>

					<FormControlLabel
						{...field}
						label={labelExceptions('false')}
						checked={field.value === 'false'}
						onChange={() => onChangeFalse()}
						control={<Radio color="primary" />}
						disabled={disabled}
					/>
				</div>
			)}
		/>
	);
});
