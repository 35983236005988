import React, { ChangeEvent, memo, useState } from 'react';
import s from './StringManyField.module.scss';
import { Controller, ControllerRenderProps, FieldValues, useFormContext } from 'react-hook-form';
import { Chip, TextField } from '@mui/material';
import { GeneratedField, StatusType } from 'src/pages/company/_BLL/types';
import { ButtonRounded } from 'src/shared/ui/buttons/ButtonRounded';

export const StringManyField: React.FC<GeneratedField> = memo(props => {
	const {
		namePrefix, //
		propertyName,
		internalName,
		measureUnits,
		validate,
	} = props;

	// * Form
	const { setValue, control, setError, clearErrors } = useFormContext();

	const name = `${namePrefix}${propertyName}.${internalName}`;

	const [newValue, setNewValue] = useState('');

	const removeItem = (valueToDelete: string, fieldName: string, fieldValue: string[]) => {
		const valueAfterRemoval = fieldValue.filter(value => value !== valueToDelete);

		if (valueAfterRemoval.length === 0) {
			setValue(`${name}.status`, StatusType.EMPTY);
		}

		setValue(fieldName, valueAfterRemoval.length === 0 ? null : valueAfterRemoval);
	};

	const changeValueHandler = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setNewValue(e.currentTarget.value);
	};

	const addNewValueHandler = (newValue: string, field: ControllerRenderProps<FieldValues, `${string}.values`>) => {
		const { name: fieldName, value: fieldValue } = field;

		setValue(`${name}.status`, StatusType.FILLED);

		if (fieldValue === null) {
			if (newValue.trim() === '') {
				setError(fieldName, { message: 'There is no value' });
			} else {
				setValue(fieldName, [newValue.trim()]);
				setNewValue('');
				clearErrors(fieldName);
			}
		} else if (fieldValue.includes(newValue)) {
			setError(fieldName, { message: 'The value already exists!' });
		} else if (newValue.trim() === '') {
			setError(fieldName, { message: 'There is no value' });
		} else {
			setValue(fieldName, [...fieldValue, newValue.trim()]);
			setNewValue('');
			clearErrors(fieldName);
		}
	};

	// * Component
	return (
		<Controller
			name={`${name}.values`}
			control={control}
			rules={{ validate: validate }}
			render={({ field, fieldState }) => (
				<div>
					<div className={s.chipsWrapper}>
						{field.value &&
							field.value.map((value: string, index: number) => {
								return (
									<Chip
										key={index}
										className={s.chip}
										label={value}
										size="small"
										onDelete={() => removeItem(value, field.name, field.value)}
									/>
								);
							})}
					</div>

					<div className={s.newValueWrapper}>
						<div className={s.fieldWrapper}>
							<TextField
								className={s.textField}
								value={newValue}
								onChange={e => changeValueHandler(e)}
								size="small"
							/>

							{measureUnits && <span className={s.additionalTextRight}>{measureUnits}</span>}
						</div>

						<ButtonRounded
							variant="contained"
							size="small"
							onClick={() => addNewValueHandler(newValue, field)}
						>
							Add
						</ButtonRounded>
					</div>

					{fieldState.error && <span className={s.error}>{fieldState.error.message}</span>}
				</div>
			)}
		/>
	);
});
