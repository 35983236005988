import { useAppSelector } from 'src/app/BLL/createAction';
import { useFormContext, useWatch } from 'react-hook-form';
import { StatusType } from 'src/pages/company/_BLL/types';

const DIVERSITY_BY_OWNERSHIP_PROPERTIES = {
	compWomanOwned25: 'compWomanOwned25', //
	compWomanOwnedMajority: 'compWomanOwnedMajority',

	compRaceOwned25: 'compRaceOwned25',
	compRaceOwnedMajority: 'compRaceOwnedMajority',
	compAsianOwned25: 'compAsianOwned25',
	compAsianOwnedMajority: 'compAsianOwnedMajority',
	compBlackOwned25: 'compBlackOwned25',
	compBlackOwnedMajority: 'compBlackOwnedMajority',
	compIndigenousOwned25: 'compIndigenousOwned25',
	compIndigenousOwnedMajority: 'compIndigenousOwnedMajority',
	compHispanicOwned25: 'compHispanicOwned25',
	compHispanicOwnedMajority: 'compHispanicOwnedMajority',

	compDisabledOwned25: 'compDisabledOwned25',
	compDisabledOwnedMajority: 'compDisabledOwnedMajority',

	compVeteranOwned25: 'compVeteranOwned25',
	compVeteranOwnedMajority: 'compVeteranOwnedMajority',

	compLGBTOwned25: 'compLGBTOwned25',
	compLGBTOwnedMajority: 'compLGBTOwnedMajority',
};

const raceDetailsPairs = [
	[DIVERSITY_BY_OWNERSHIP_PROPERTIES.compAsianOwned25, DIVERSITY_BY_OWNERSHIP_PROPERTIES.compAsianOwnedMajority],
	[DIVERSITY_BY_OWNERSHIP_PROPERTIES.compBlackOwned25, DIVERSITY_BY_OWNERSHIP_PROPERTIES.compBlackOwnedMajority],
	[DIVERSITY_BY_OWNERSHIP_PROPERTIES.compIndigenousOwned25, DIVERSITY_BY_OWNERSHIP_PROPERTIES.compIndigenousOwnedMajority],
	[DIVERSITY_BY_OWNERSHIP_PROPERTIES.compHispanicOwned25, DIVERSITY_BY_OWNERSHIP_PROPERTIES.compHispanicOwnedMajority],
];

const pairsList = [
	[DIVERSITY_BY_OWNERSHIP_PROPERTIES.compWomanOwned25, DIVERSITY_BY_OWNERSHIP_PROPERTIES.compWomanOwnedMajority],
	[DIVERSITY_BY_OWNERSHIP_PROPERTIES.compRaceOwned25, DIVERSITY_BY_OWNERSHIP_PROPERTIES.compRaceOwnedMajority],
	[DIVERSITY_BY_OWNERSHIP_PROPERTIES.compDisabledOwned25, DIVERSITY_BY_OWNERSHIP_PROPERTIES.compDisabledOwnedMajority],
	[DIVERSITY_BY_OWNERSHIP_PROPERTIES.compVeteranOwned25, DIVERSITY_BY_OWNERSHIP_PROPERTIES.compVeteranOwnedMajority],
	[DIVERSITY_BY_OWNERSHIP_PROPERTIES.compLGBTOwned25, DIVERSITY_BY_OWNERSHIP_PROPERTIES.compLGBTOwnedMajority],
	...raceDetailsPairs,
];

const getName = (propertyName: string) => {
	return `properties.${propertyName}.dataPoint`;
};

export const useOwned = (propertyName: string) => {
	const activeMenu = useAppSelector(state => state.company.activeMenu);

	const { control, setValue } = useFormContext();

	const inDiversityByOwnership = activeMenu === 'Diversity by Ownership';
	const otherTab = !inDiversityByOwnership;

	const owned = pairsList.find(pair => pair.includes(propertyName));

	const owned25Name = getName(owned ? owned[0] : '');
	const majorityName = getName(owned ? owned[1] : '');
	const owned25RaceName = getName(DIVERSITY_BY_OWNERSHIP_PROPERTIES.compRaceOwned25);
	const majorityRaceName = getName(DIVERSITY_BY_OWNERSHIP_PROPERTIES.compRaceOwnedMajority);

	const owned25Field = useWatch({ name: owned25Name, control, disabled: otherTab || !owned25Name });
	const majorityField = useWatch({ name: majorityName, control, disabled: otherTab || !majorityName });
	const owned25RaceField = useWatch({ name: owned25RaceName, control, disabled: otherTab });
	const majorityRaceField = useWatch({ name: majorityRaceName, control, disabled: otherTab });

	const isOwned25 = owned && owned[0] === propertyName;
	const isMajority = owned && owned[1] === propertyName;
	const isRaceOwned25 = owned && owned[0] === DIVERSITY_BY_OWNERSHIP_PROPERTIES.compRaceOwned25 && isOwned25;
	const isRaceMajority = owned && owned[1] === DIVERSITY_BY_OWNERSHIP_PROPERTIES.compRaceOwnedMajority && isMajority;

	// TODO: disable rest race if main race is null.
	const isOwned25RaceField = !!raceDetailsPairs.find(pair => pair[0] === propertyName);
	const isOwnedMajorityField = !!raceDetailsPairs.find(pair => pair[1] === propertyName);
	const owned25RaceDisabled = isOwned25RaceField && (owned25RaceField.value === null || owned25RaceField.value === 'false');
	const majorityRaceDisabled = isOwnedMajorityField && (majorityRaceField.value === null || majorityRaceField.value === 'false');
	const disabled = owned25RaceDisabled || majorityRaceDisabled;

	const handleOwnedTrue = () => {
		if (isMajority) {
			if (owned25Field.value === 'false' || majorityField.value === null) {
				setValue(`${owned25Name}.value`, 'true');
				setValue(`${owned25Name}.status`, StatusType.FILLED);
			}
		}
	};

	const handleOwnedFalse = () => {
		if (isOwned25) {
			if (majorityField.value === 'true' || majorityField.value === null) {
				setValue(`${majorityName}.value`, 'false');
				setValue(`${majorityName}.status`, StatusType.FILLED);
			}
		}

		if (isRaceOwned25) {
			raceDetailsPairs.forEach(pair => {
				const owned25Name = getName(pair[0]);
				const majorityName = getName(pair[1]);
				setValue(`${owned25Name}.value`, 'false');
				setValue(`${owned25Name}.status`, StatusType.FILLED);
				setValue(`${majorityName}.value`, 'false');
				setValue(`${majorityName}.status`, StatusType.FILLED);
			});
		}

		if (isRaceMajority) {
			raceDetailsPairs.forEach(pair => {
				const majorityName = getName(pair[1]);
				setValue(`${majorityName}.value`, 'false');
				setValue(`${majorityName}.status`, StatusType.FILLED);
			});
		}
	};

	return { handleOwnedTrue, handleOwnedFalse, disabled };
};
