import {RootState} from './rootReducer';

export const localStorageName = process.env.REACT_APP_LOCAL_STORAGE_NAME || 'Default value is located in webpack config';

export const loadState = () => {

	try {
		const serializedState = localStorage.getItem(localStorageName);
		return serializedState ? JSON.parse(serializedState) : undefined;

	} catch (error) {
		return undefined;
	}
};

export const saveState = (state: RootState) => {

	try {
		const serializedState = JSON.stringify(state);
		localStorage.setItem(localStorageName, serializedState);

	} catch (error) {
		// Ignore
	}
};
