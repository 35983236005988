import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/app/BLL/createAction';
import { RatingType } from '../MainPage/_BLL/types';
import { CompanyForm } from './ui/CompanyForm/CompanyForm';
import { Preloader } from 'src/shared/ui/loaders/Preloader';
import { actionsCompany } from 'src/pages/company/_BLL/slice';
import { PageDecorator } from 'src/app/ui/decorators/PageDecorator';

export const CompanyPage: React.FC = () => {
	// * Selectors
	const meta = useAppSelector(state => state.company.meta);
	const menuItems = useAppSelector(state => state.company.menuItems);
	const currentCompany = useAppSelector(state => state.company.company);

	// * Actions
	const dispatch = useAppDispatch();
	const { getMeta, getCompany } = actionsCompany;

	// * Router
	const { ratingType, companyId } = useParams<{ ratingType: RatingType; companyId: string }>();

	// * conditions
	const menuItemsLoaded = ratingType && menuItems[ratingType];
	const companyMeta = ratingType && meta[ratingType];

	const companyRatingMatched = (ratingType === 'advanced' && currentCompany?.accessType === 1) || (ratingType === 'certified' && currentCompany?.accessType === 2);

	const companyReady = companyMeta && menuItemsLoaded && currentCompany && companyRatingMatched;

	// * Load meta
	useEffect(() => {
		if (!companyMeta && ratingType) {
			dispatch(getMeta({ ratingType }));
		}
	}, [ratingType]);

	// * Load company
	useEffect(() => {
		if (ratingType && companyId) {
			dispatch(
				getCompany({
					id: companyId,
					ratingType,
				}),
			);
		}
	}, []);

	// * Component
	return (
		<PageDecorator>
			{companyReady ? (
				<CompanyForm
					currentCompany={currentCompany}
					companyMeta={companyMeta}
					ratingType={ratingType}
				/>
			) : (
				<Preloader />
			)}
		</PageDecorator>
	);
};
