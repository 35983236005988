import React, { useEffect } from 'react';
import s from './SignInPage.module.scss';
import { Logo } from 'src/shared/ui/Logo';
import { SignInForm } from 'src/pages/auth/SignInForm/SignInForm';

export const SignInPage: React.FC = () => {

	useEffect(() => {
		localStorage.clear();
	}, []);

	return (<>
		<div className={s.container}>
			<div className={s.paper}>
				<Logo className={s.logo}/>
				<SignInForm/>
			</div>
		</div>
	</>);
};
