import React, {memo} from 'react';
import s from './CriticalErrorModalContent.module.scss';
import {ErrorCritical} from '../_BLL/notifications/types';

interface Props {
	error: ErrorCritical | null;
}

export const CriticalErrorModalContent: React.FC<Props> = memo(props => {

	const {
		error
	} = props;

	// * Render
	return (<>
		{error &&
			<div className={s.container}>
				<h2 className={s.title}>
					{error.message}
				</h2>

				<div className={s.error}>
					<span>Error code: </span><span className={s.errorCode}>{error.incidentId}</span>
				</div>

				<p>
					Seems like something went wrong. If this happens again please take a screenshot and send to <a
						href="mailto:support@denominator.com">support@denominator.com</a>
				</p>
			</div>
		}
	</>);
});
