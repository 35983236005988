import React, { useState } from 'react';
import s from './HeaderProfile.module.scss';
import { useAppDispatch } from 'src/app/BLL/createAction';
import { UserData } from 'src/pages/auth/_BLL/types';
import { actionsAuth } from 'src/pages/auth/_BLL/slice';
import { ButtonRounded } from 'src/shared/ui/buttons/ButtonRounded';

interface Props {
	userData: UserData;
}

export const HeaderProfile: React.FC<Props> = props => {

	const {
		userData
	} = props;

	// * Actions
	const dispatch = useAppDispatch();
	const {logout} = actionsAuth;

	const [open, setOpen] = useState<boolean>(false);

	const handleLogout = () => dispatch(logout());

	// * Render
	return (
		<div className={s.container}>
			<span onClick={() => setOpen(!open)}>
				{'displayName' in userData ? userData.displayName : 'User'}
			</span>

			{open && (
				<div className={s.menu}>
					<ButtonRounded
						onClick={handleLogout}
					>
						Logout
					</ButtonRounded>
				</div>
			)}
		</div>
	);
};
