import React from 'react';
import s from './FieldGeneratorCompany.module.scss';
import { StatusField } from 'src/pages/company/ui/dataPointGenerator/fields/StatusField/StatusField';
import { DataPointGenerator } from 'src/pages/company/ui/dataPointGenerator/DataPointGenerator';
import { PropertyMeta } from 'src/pages/company/_BLL/types';
import { DashboardStyles } from 'src/pages/company/_styles/DashboardStyles';
import { Dictionaries } from 'src/pages/app/_BLL/metaDictionaries/types';

interface Props {
	orderPrefix: string;
	meta: PropertyMeta;
	dictionaries: Dictionaries;
	namePrefix: string;
}

export const FieldGeneratorCompany: React.FC<Props> = props => {
	const {
		orderPrefix,
		meta: {
			propertyName,
			handlingType,
			displayName,
			description,
			showInHeader, // There is no header.
			dataPoints,
		},
		dictionaries,
		namePrefix,
	} = props;

	// * Styles
	const CD = DashboardStyles;

	// * Component
	return (
		<div className={s.container}>
			<div className={s.label}>
				<CD.Order>{orderPrefix}</CD.Order>
				<div className={s.question}>
					<div className={s.question__text}>{displayName}</div>
					<div className={s.question__description}>{description}</div>
				</div>
			</div>

			<div className={s.fields}>
				<div className={s.field}>
					<div className={s.dataPoint}>
						{dataPoints.map(dataPoint => (
							<DataPointGenerator
								key={dataPoint.internalName}
								propertyName={propertyName}
								datapointMeta={dataPoint}
								dictionaries={dictionaries}
								namePrefix={namePrefix}
								handlingType={handlingType}
							/>
						))}
					</div>
				</div>

				<StatusField
					namePrefix={namePrefix}
					propertyName={propertyName}
					dataPoints={dataPoints}
				/>
			</div>
		</div>
	);
};
