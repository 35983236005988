import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useAppSelector } from 'src/app/BLL/createAction';
import { MainPage } from 'src/pages/MainPage';
import { CompanyPage } from '../../company/CompanyPage';
import { GreetingsPage } from '../../auth/GreetingsPage';

export enum RouterPath {
	mainPage = '/main',
	companyPage = '/company'
}

export const AppRouter = () => {

	const userData = useAppSelector(state => state.auth.userData);
	const dictionaries = useAppSelector(state => state.metaDictionaries.dictionaries);

	// * Conditions
	const userLoggedIn = userData && dictionaries;
	const userBasicAccess = userData?.accessRights.includes('CompanyOwnDataAccess');

	return (
		<BrowserRouter>
			{/*<ScrollToTop/>*/}
			<Routes>
				<Route
					path="/"
					element={userLoggedIn ? <Navigate to={RouterPath.mainPage} replace/>
						: <GreetingsPage/>}
				/>

				<Route
					path={RouterPath.mainPage}
					element={userLoggedIn ? <MainPage/> :
						<Navigate to="/" replace/>}
				/>

				<Route
					path={`${RouterPath.companyPage}/:ratingType/:companyId`}
					element={userLoggedIn && userBasicAccess ? <CompanyPage/> : <Navigate to="/" replace/>}
				/>

				<Route
					path="*"
					element={<div>404</div>}
				/>
			</Routes>
		</BrowserRouter>
	);
};
