export enum RequestStatus {
	still = 'STILL',
	loading = 'LOADING',
	failed = 'FAILED',
}

export enum UrlAPI {
	// Authentication
	login = 'login',
	logout = 'logout',
	dictionaries = 'dictionaries',

	// Companies info
	getCompaniesInfo = 'companiesLookup',

	// Company rating dashboard
	advancedMeta = 'advancedOwnCompaniesMetadata',
	certifiedMeta = 'certifiedOwnCompaniesMetadata',
	company = 'company',
}

