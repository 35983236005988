import React from 'react';
import s from './CertificateDownload.module.scss';
import ReactPDF, { PDFDownloadLink } from '@react-pdf/renderer';
import { CertificateTemplate } from 'src/pages/MainPage/components/CertificateTemplate';
import { ButtonSquared } from 'src/shared/ui/buttons/ButtonSquared';
import PDFViewer = ReactPDF.PDFViewer;

interface Props {
	companyId: number;
	companyName: string;
	score: number;
	absoluteRating: string;
	relativeRating: string;
	dateOfIssue: Date;
}

export const CertificateDownload: React.FC<Props> = props => {
	const { companyId, companyName, score, absoluteRating, relativeRating, dateOfIssue } = props;

	const getOneYearFromNow = () => {
		const today = new Date();
		return new Date(today.setFullYear(today.getFullYear() + 1));
	};

	const certificate = {
		companyId,
		companyName,
		dateOfIssue,
		validUntil: getOneYearFromNow(),
		score, // ? We should change rating to score in API to prevent confusion.
		absoluteRating,
		relativeRating,
	};

	// * Render
	return (
		<>
			{certificate && (
				<div className={s.container}>
					<PDFViewer className={s.pdfViewer}>
						<CertificateTemplate {...certificate} />
					</PDFViewer>

					<PDFDownloadLink
						document={<CertificateTemplate {...certificate} />}
						fileName="certificate.pdf"
					>
						{/* @ts-ignore */}
						{({ loading }) => (loading ? 'Loading document...' : <ButtonSquared>Download certificate</ButtonSquared>)}
					</PDFDownloadLink>
				</div>
			)}
		</>
	);
};
