import { Paragraph } from 'src/shared/ui/Paragraph';
import { Heading } from 'src/shared/ui/Heading';

export const SubmitForRating = () => {
	return (
		<>
			<Heading
				level={1}
				marginBottom="m"
			>
				Thank you for submitting the company information for calculation of Denominator&apos;s Advanced Score.
			</Heading>

			<Paragraph>Data is currently being processed and need to pass quality controls before the final Advanced Score is available.</Paragraph>

			<Paragraph>Please check the main landing page in 24 hours and look for the Advanced Score icon.</Paragraph>

			<Paragraph>
				In case you have changes or additional data in he future - please do not hesitate to edit/input new data and re-submit for calculation of updated Advanced Score
			</Paragraph>
		</>
	);
};
