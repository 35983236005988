import { rootReducer } from './rootReducer';
import { loadState, saveState } from './localStorage';
import { initialState as notifications } from '../../pages/app/_BLL/notifications/slice';
import { initialState as userInfo } from '../../pages/MainPage/_BLL/slice';
import { initialState as company} from 'src/pages/company/_BLL/slice';
import { configureStore } from '@reduxjs/toolkit';

const preloadedState = loadState();

export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		immutableCheck: false
	}),
	devTools: {trace: false},
	preloadedState
});

store.subscribe(() => {
	saveState({
		notifications,
		auth: store.getState().auth,
		userInfo,
		metaDictionaries: store.getState().metaDictionaries,
		company,
	});
});

export type AppDispatch = typeof store.dispatch
