import {FC, HTMLAttributes, memo} from 'react';
import logoImg from 'src/shared/assets/img/dinominator_logo.png';

export const Logo: FC<HTMLAttributes<HTMLDivElement>> = memo(props => {

	// TODO: Logo to link copy from gold/silver
	return (
		<div {...props}>
			<img src={logoImg} alt={'DI logo'}/>
		</div>
	);
});
