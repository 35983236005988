import React from 'react';
import s from './NotificationProvider.module.scss';
import {Notification} from '../../_BLL/notifications/types';
import {NotificationSingle} from './NotificationSingle';

interface Props {
	notifications: Notification[];
}

export const NotificationProvider: React.FC<Props> = props => {

	const {
		notifications
	} = props;

	return (
		<div className={s.container}>
			{notifications.map(note => {
				return <NotificationSingle
					key={note.id}
					id={note.id}
					message={note.message}
					type={note.type}
				/>;
			})}
		</div>
	);
};
