import cn from 'classnames';
import React from 'react';
import s from 'src/entities/Menu/ui/NavItem/NavItem.module.scss';
import { DashBoardMenuName, SingleMenuItem } from 'src/pages/company/_BLL/types';

export type SetActiveMenuItem = (menuItemName: DashBoardMenuName) => void;

interface Props extends SingleMenuItem {
	index: number;
	onClick: SetActiveMenuItem;
	activeMenuItem: boolean;
}

export const NavItem: React.FC<Props> = props => {
	const {
		index, //
		name,
		value,
		totalValue,
		activeMenuItem,
		onClick,
	} = props;

	// * Render
	return (
		<div
			className={cn(s.container, activeMenuItem && s.container_active)}
			onClick={() => onClick(name)}
		>
			{name !== 'Welcome' && name !== 'Submit for rating' && <span>{`S.${index} `}</span>}
			<span className={s.name}>{`${name} `}</span>
			{value !== null && <span>{`(${value}`}</span>}
			{totalValue && <span>{`/${totalValue}`}</span>}
			{value !== null && <span>{')'}</span>}
		</div>
	);
};
