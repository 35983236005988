import React from 'react';
import { useSelector } from 'react-redux';
import { FieldGeneratorCompany } from 'src/pages/company/ui/FieldGeneratorCompany/FieldGeneratorCompany';
import { MetaGroup, PropertyMeta } from 'src/pages/company/_BLL/types';
import { RootState } from 'src/app/BLL/rootReducer';
import { Heading } from 'src/shared/ui/Heading';

interface Props {
	companyMetaGroup: MetaGroup;
	menuOrder: number;
	groupOrder: number;
}

export const FormGroupCompany: React.FC<Props> = props => {
	const {
		companyMetaGroup, //
		menuOrder,
		groupOrder,
	} = props;

	// * Selector
	const dictionaries = useSelector((state: RootState) => state.metaDictionaries.dictionaries);

	// * Render
	return (
		<div>
			<Heading
				level={2}
				size={3}
				marginBottom="m"
			>
				{companyMetaGroup.name}
			</Heading>

			{dictionaries &&
				companyMetaGroup.properties
					.filter((property: PropertyMeta) => property.propertyName !== 'id') // ! HACK: Hide id field
					.map((property: PropertyMeta, index) => (
						<FieldGeneratorCompany
							key={property.propertyName}
							orderPrefix={`${menuOrder}.${groupOrder}.${index + 1}`}
							meta={property}
							dictionaries={dictionaries}
							namePrefix="properties."
						/>
					))}
		</div>
	);
};
