import CircularProgress, {CircularProgressProps} from '@mui/material/CircularProgress';
import React from 'react';

export const Preloader: React.FC<CircularProgressProps> = props => {

	const {
		...restProps
	} = props;

	return (
		<CircularProgress {...restProps}/>
	);
};
