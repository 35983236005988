import React, { useCallback, useRef } from 'react';
import s from './RatingWidgetDownload.module.scss';
import { ButtonSquared } from 'src/shared/ui/buttons/ButtonSquared';
import { WidgetWithRatings } from 'src/entities/widgets/WidgetWithRatings';
import { Widget } from 'src/entities/widgets/Widget';

interface Props {
	dateOfIssue: Date;
	score: number;
	absoluteRating: string;
	relativeRating: string;
}

export const RatingWidgetDownload: React.FC<Props> = props => {
	const {
		dateOfIssue,
		score,
		absoluteRating,
		relativeRating
	} = props;

	function downloadBlob(blob: Blob, filename: string) {
		const objectUrl = URL.createObjectURL(blob);

		const link = document.createElement('a');
		link.href = objectUrl;
		link.download = filename;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);

		setTimeout(() => URL.revokeObjectURL(objectUrl), 5000);
	}

	const widgetWithRatingRef: any = useRef();
	const widgetRef: any = useRef();

	const downloadSVG = useCallback((ref: React.MutableRefObject<any>) => {
		const svg = ref.current.innerHTML;
		const blob = new Blob([svg], {type: 'image/svg+xml'});
		downloadBlob(blob, 'smallCertificate.svg');
	}, []);

	const yearOfIssue = dateOfIssue.getFullYear();

	// * Render
	return (
		<div className={s.container}>
			<div className={s.widget}>
				<div ref={widgetWithRatingRef}>
					<WidgetWithRatings
						absoluteRating={absoluteRating}
						relativeRating={relativeRating}
						yearOfIssue={yearOfIssue}
					/>
				</div>

				<ButtonSquared
					onClick={() => downloadSVG(widgetWithRatingRef)}
				>
					Download SVG widget with ratings
				</ButtonSquared>
			</div>

			<div className={s.widget}>
				<div ref={widgetRef}>
					<Widget
						yearOfIssue={yearOfIssue}
					/>
				</div>

				<ButtonSquared
					onClick={() => downloadSVG(widgetRef)}
				>
					Download SVG widget
				</ButtonSquared>
			</div>
		</div>
	);
};
