import React from 'react';

interface Props {
	absoluteRating: string;
	relativeRating: string;
	yearOfIssue: number,
}

export const WidgetWithRatings: React.FC<Props> = props => {
	const {
		absoluteRating,
		relativeRating,
		yearOfIssue
	} = props;

	// * Render
	return (
		<svg width="427.5" height="125.5" viewBox="0 0 855 251" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_2411_2)">
				<rect width="855" height="251" rx="5" fill="white"/>
				<rect x="2.5" y="2.5" width="850" height="246" fill="white" stroke="#02356C" strokeWidth="5"/>
				<rect x="18.5" y="106.5" width="212" height="81" rx="4.5" fill="#02356C"/>
				<rect x="18.5" y="106.5" width="212" height="81" rx="4.5" stroke="#02356C"/>
				<text
					fill="white"
					xmlSpace="preserve"
					style={{whiteSpace: 'pre'}}
					fontFamily="Arial"
					fontSize="18"
					fontWeight="bold"
					letterSpacing="0em"
				>
					<tspan x="31" y="134.74">Relative&#10;</tspan>
					<tspan x="31" y="155.74">Rating:</tspan>
				</text>
				<text
					fill="white"
					xmlSpace="preserve"
					style={{whiteSpace: 'pre'}}
					fontFamily="Arial"
					fontSize="50"
					fontWeight="bold"
					letterSpacing="0em"
				>
					<tspan x="119.21" y="164.834">{relativeRating}</tspan>
				</text>
				<rect x="18.5" y="16.5" width="212" height="81" rx="4.5" fill="white"/>
				<rect x="18.5" y="16.5" width="212" height="81" rx="4.5" stroke="#02356C"/>
				<text
					fill="#02356C"
					xmlSpace="preserve"
					style={{whiteSpace: 'pre'}}
					fontFamily="Arial"
					fontSize="50"
					fontWeight="bold"
					letterSpacing="0em"
				>
					<tspan x="120.71" y="74.834">{absoluteRating}</tspan>
				</text>
				<text
					fill="#02356C"
					xmlSpace="preserve"
					style={{whiteSpace: 'pre'}}
					fontFamily="Arial"
					fontSize="18"
					fontWeight="bold"
					letterSpacing="0em"
				>
					<tspan x="34" y="44.7402">Absolute&#10;</tspan>
					<tspan x="34" y="65.7402">Rating:</tspan>
				</text>
				<line x1="259.5" y1="21" x2="259.5" y2="230" stroke="#02356C" strokeWidth="3"/>
				<text
					fill="#02356C"
					xmlSpace="preserve"
					style={{whiteSpace: 'pre'}}
					fontFamily="Arial"
					fontSize="38"
					fontWeight="bold"
					letterSpacing="0em"
				>
					<tspan x="287" y="94.6738">{yearOfIssue} </tspan>
					<tspan x="287" y="138.674">Diversity, Equity, Inclusion</tspan>
				</text>
				<text
					fill="#02356C"
					xmlSpace="preserve"
					style={{whiteSpace: 'pre'}}
					fontFamily="Arial"
					fontSize="38"
					letterSpacing="0em"
				>
					<tspan x="382.186" y="94.6738">Denominator &#xa9; certified</tspan>
					<tspan x="287" y="182.674">score &#38; rating</tspan>
				</text>
				<g clipPath="url(#clip1_2411_2)">
					<path d="M26 202.87V206.73H224V199H26V202.87Z" fill="#02356C"/>
					<path
						d="M134.44 209.94C134.111 210.099 133.843 210.361 133.675 210.685C133.508 211.01 133.45 211.38 133.51 211.74C133.513 212.019 133.57 212.295 133.68 212.552C133.79 212.809 133.949 213.042 134.149 213.237C134.348 213.432 134.585 213.586 134.844 213.689C135.104 213.793 135.381 213.844 135.66 213.84C136.05 213.843 136.432 213.732 136.76 213.52C137.039 213.346 137.272 213.108 137.441 212.826C137.61 212.544 137.71 212.226 137.732 211.899C137.754 211.571 137.699 211.242 137.57 210.94C137.441 210.638 137.242 210.371 136.99 210.16C136.641 209.862 136.208 209.68 135.75 209.641C135.293 209.602 134.835 209.706 134.44 209.94Z"
						fill="#02356C"/>
					<path
						d="M40.2901 211.18C39.5325 211.23 38.7782 211.32 38.0302 211.45L37.6001 211.59V231.06L38.1401 231.17C38.4301 231.25 40.1401 231.35 41.9501 231.41C43.5252 231.561 45.1135 231.494 46.6701 231.21C48.5426 230.857 50.2764 229.979 51.6701 228.68C53.6701 226.77 54.4501 224.68 54.4501 221.24C54.4501 216.07 52.0301 212.77 47.3601 211.57C45.0343 211.102 42.6533 210.97 40.2901 211.18ZM47.2301 214.84C48.589 215.531 49.6539 216.688 50.2301 218.1C50.6232 219.35 50.7638 220.666 50.6436 221.97C50.5234 223.275 50.145 224.543 49.5302 225.7C48.3702 227.5 46.7802 228.2 43.5302 228.35L41.2501 228.43V214.24L43.7201 214.31C44.9149 214.224 46.1139 214.405 47.2301 214.84Z"
						fill="#02356C"/>
					<path
						d="M176.92 211.67C176.82 211.67 176.09 211.84 175.31 211.96L173.9 212.19L173.96 220.06L174.02 227.91L174.54 229.01C174.83 229.657 175.274 230.222 175.834 230.656C176.394 231.09 177.052 231.38 177.75 231.5C179.286 231.745 180.857 231.646 182.35 231.21L183.35 230.9L183.08 229.61C183.003 229.142 182.879 228.683 182.71 228.24C182.449 228.232 182.189 228.284 181.95 228.39C180.805 228.743 179.577 228.715 178.45 228.31C177.53 227.69 177.39 227.02 177.39 222.88V219.11H182.8V216.21H177.4V213.89C177.444 213.121 177.4 212.349 177.27 211.59C177.15 211.596 177.031 211.624 176.92 211.67Z"
						fill="#02356C"/>
					<path
						d="M62.6 216.09C59.34 217.16 57.52 219.98 57.52 223.92C57.5606 225.336 57.8622 226.733 58.41 228.04C59.1694 229.335 60.2926 230.378 61.64 231.04C62.74 231.54 63.03 231.58 65.16 231.6C66.7585 231.701 68.3609 231.477 69.87 230.94C70.28 230.69 69.78 227.94 69.35 228.12C67.4905 228.652 65.5338 228.751 63.63 228.41C62.9799 228.179 62.4072 227.771 61.9761 227.232C61.5451 226.693 61.2729 226.045 61.19 225.36V224.93H71.05V223.33C71.0517 222.519 70.9882 221.71 70.86 220.91C70.6696 219.943 70.2607 219.032 69.6645 218.247C69.0683 217.462 68.3006 216.823 67.42 216.38C65.8697 215.846 64.2031 215.746 62.6 216.09ZM66.03 219.15C66.4338 219.383 66.7777 219.706 67.0345 220.095C67.2914 220.484 67.4542 220.927 67.51 221.39L67.63 222.2H64.4C60.82 222.2 60.92 222.2 61.48 220.89C61.6282 220.452 61.871 220.052 62.191 219.719C62.5111 219.385 62.9006 219.126 63.332 218.96C63.7634 218.794 64.2261 218.725 64.6871 218.758C65.1482 218.791 65.5965 218.924 66 219.15H66.03Z"
						fill="#02356C"/>
					<path
						d="M78.2299 216.02C77.5499 216.11 76.54 216.29 75.96 216.4L74.96 216.62V231.29H78.2499V219.15L78.6899 219.03C79.9326 218.836 81.2017 218.898 82.4199 219.21C82.678 219.319 82.9102 219.482 83.1014 219.687C83.2927 219.892 83.4387 220.135 83.53 220.4C83.91 221.27 83.9499 221.7 84.0099 226.3L84.09 231.3H87.53V226.99C87.53 224.63 87.43 222.04 87.33 221.25C86.99 218.66 85.8299 216.99 83.8699 216.32C82.0229 215.884 80.1128 215.783 78.2299 216.02Z"
						fill="#02356C"/>
					<path
						d="M96.0899 216.07C92.3199 217.33 90.3699 220.81 90.9699 225.16C91.5299 229.03 93.9699 231.45 97.6599 231.64C102.22 231.89 105.28 228.7 105.28 223.64C105.365 222.156 105.012 220.68 104.265 219.394C103.519 218.109 102.411 217.071 101.08 216.41C99.4849 215.815 97.751 215.697 96.0899 216.07ZM99.6899 219.21C101.1 219.94 101.95 222.16 101.69 224.47C101.38 227.54 99.5199 229.22 97.1599 228.58C95.3499 228.1 94.4199 226.46 94.4199 223.77C94.4199 221.47 95.1199 219.86 96.4199 219.24C97.4705 218.835 98.6321 218.825 99.6899 219.21Z"
						fill="#02356C"/>
					<path
						d="M112.39 216.02C111.7 216.09 110.63 216.29 110.03 216.42L108.95 216.65V231.29H112.43V225.22C112.43 219.86 112.43 219.13 112.74 219.03C113.862 218.829 115.016 218.891 116.11 219.21C117.28 219.81 117.36 220.21 117.42 226.03L117.48 231.29H121L120.94 225.84C120.962 223.897 120.886 221.955 120.71 220.02C120.34 219.24 123.47 218.57 124.71 219.15C125.119 219.421 125.441 219.802 125.64 220.25C126.06 221.1 126.08 221.41 126.14 226.25L126.22 231.32H129.7L129.6 225.95C129.51 220.3 129.37 219.33 128.6 218.11C127.07 215.79 123.49 215.23 120.3 216.8C119.16 217.38 119.14 217.38 118.76 217.01C117.73 216.05 115.18 215.67 112.39 216.02Z"
						fill="#02356C"/>
					<path
						d="M145.26 216.02C144.57 216.11 143.5 216.29 142.91 216.42L141.82 216.65V231.29H145.3V219.15L145.75 219.03C147.001 218.78 148.297 218.873 149.5 219.3C150.74 220.06 150.81 220.39 150.87 226.11L150.93 231.29H154.43L154.35 225.82C154.27 220.75 154.26 220.29 153.85 219.3C152.73 216.46 150.1 215.45 145.26 216.02Z"
						fill="#02356C"/>
					<path
						d="M161 216.02C159.59 216.27 158.93 216.56 159.05 216.87C159.162 217.313 159.239 217.765 159.28 218.22C159.4 219.11 159.47 219.3 159.75 219.22C160.03 219.14 160.52 219.04 161.1 218.93C164.44 218.31 166.34 219.24 166.34 221.5V222.35L165.24 222.17C162.09 221.65 158.91 222.81 158.01 224.8C157.596 225.786 157.504 226.877 157.746 227.919C157.988 228.96 158.553 229.898 159.36 230.6C160.92 231.69 164.36 231.92 168.36 231.24L169.71 230.99V225.5C169.71 220.78 169.71 219.87 169.38 219.14C169.194 218.556 168.887 218.018 168.479 217.561C168.07 217.104 167.57 216.739 167.01 216.49C166.06 216.027 165.003 215.827 163.95 215.91C162.965 215.866 161.979 215.903 161 216.02ZM165.74 224.64L166.34 224.76V228.76L165.1 228.89C163.46 229.05 162.01 228.74 161.5 228.1C161.235 227.706 161.099 227.24 161.11 226.766C161.121 226.291 161.278 225.832 161.56 225.45C162.26 224.66 164.14 224.31 165.78 224.6L165.74 224.64Z"
						fill="#02356C"/>
					<path
						d="M190 216.17C186.91 217.17 185 220.1 185 223.75C185 226.21 186.2 229.03 187.73 230.19C188.327 230.706 189.021 231.096 189.772 231.338C190.523 231.581 191.314 231.669 192.1 231.6C193.247 231.751 194.412 231.527 195.42 230.96C197.61 229.9 198.84 228.06 199.29 225.26C199.87 221.49 198.17 217.81 195.29 216.48C193.608 215.801 191.75 215.692 190 216.17ZM193.89 219.28C194.386 219.621 194.811 220.056 195.14 220.56C195.7 221.47 195.72 221.56 195.72 223.73C195.72 225.9 195.72 226.05 195.24 226.84C195.004 227.327 194.652 227.749 194.214 228.069C193.777 228.388 193.268 228.595 192.732 228.672C192.196 228.749 191.649 228.694 191.14 228.51C190.631 228.327 190.174 228.021 189.81 227.62C188.96 226.71 188.59 225.51 188.61 223.52C188.533 222.614 188.728 221.705 189.17 220.91C189.372 220.288 189.779 219.752 190.323 219.389C190.867 219.026 191.518 218.858 192.17 218.91C192.747 218.906 193.318 219.033 193.84 219.28H193.89Z"
						fill="#02356C"/>
					<path
						d="M207.08 215.94C206.77 215.94 205.75 216.21 204.82 216.42L203.12 216.83V231.29H206.4V219.29L206.95 219.08C208.39 218.886 209.853 218.926 211.28 219.2C211.53 219.29 211.63 219.12 211.82 218.2C211.93 217.58 212.09 216.92 212.13 216.75C212.17 216.58 212.05 216.38 211.49 216.23C210.037 215.961 208.556 215.863 207.08 215.94Z"
						fill="#02356C"/>
					<path d="M133.89 223.75V231.29H137.38V216.21H133.89V223.75Z" fill="#02356C"/>
				</g>
			</g>
			<defs>
				<clipPath id="clip0_2411_2">
					<rect width="855" height="251" rx="5" fill="white"/>
				</clipPath>
				<clipPath id="clip1_2411_2">
					<rect width="198" height="32.65" fill="white" transform="translate(26 199)"/>
				</clipPath>
			</defs>
		</svg>
	);
};
