import React, {memo, useCallback, useEffect, useState} from 'react';
import s from './NotificationSingle.module.scss';
import {Notification} from '../../_BLL/notifications/types';
import {actionsNotifications} from '../../_BLL/notifications/slice';
import cn from 'classnames';
import { useAppDispatch } from 'src/app/BLL/createAction';

export const NotificationSingle: React.FC<Notification> = memo(props => {

	const {
		id,
		message,
		type,
	} = props;

	// * Actions
	const dispatch = useAppDispatch();
	const {deleteNotification} = actionsNotifications;

	// * Local state
	const [close, setClose] = useState(false);
	const [barWidth, setBarWidth] = useState<number>(0);
	const [intervalId, setIntervalId] = useState<ReturnType<typeof setInterval> | null>(null);

	const handleStartTimer = useCallback(() => {
		const id = setInterval(() => {
			setBarWidth(prevState => {
				if (prevState < 100) {
					return prevState + 0.5;
				}
				return prevState;
			});
		}, 20);

		setIntervalId(id);
	}, []);


	const handlePauseTimer = useCallback(() => {
		clearInterval(intervalId as ReturnType<typeof setInterval>);
	}, [intervalId],);

	useEffect(() => {
		handleStartTimer();
	}, [handleStartTimer]);

	useEffect(() => {
		const handleCloseNotification = () => {
			handlePauseTimer();
			setClose(true);
			setTimeout(() => {
				dispatch(deleteNotification({id}));
			}, 400);
		};

		if (barWidth === 100) {
			handleCloseNotification();
		}
	}, [barWidth, handlePauseTimer, id]);

	// * Render
	return (
		<div
			className={cn(s.container, close && s.container_exit)}
			onMouseEnter={handlePauseTimer}
			onMouseLeave={handleStartTimer}
			// type={type}
			// exit={close}
		>
			<p>{message}</p>

			<div
				className={cn(s.bar, s[`bar_${type}`])}
				style={{width: `${barWidth}%`}}
			/>
		</div>
	);
});
