import { MenuItem, Select } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useFocus } from 'src/shared/hooks/useFocus';
import { DictionaryItem } from 'src/pages/app/_BLL/metaDictionaries/types';
import { GeneratedField, StatusType } from 'src/pages/company/_BLL/types';
import { ValueDictionaryToolTip } from 'src/pages/company/ui/dataPointGenerator/fields/_field-elements/ValueDictionaryTooltip/ValueDictionaryTooltip';
import s from './DropDownField.module.scss';

export const DropDownField: React.FC<GeneratedField> = memo(props => {
	const { namePrefix, propertyName, internalName, validate, fieldDictionary } = props;

	// * Form
	const { setValue, control } = useFormContext();

	// * Search
	const [filterValue, setFilterValue] = useState('');
	const [inputRef, setInputFocus] = useFocus();

	useEffect(() => {
		setInputFocus();
	});

	const name = `${namePrefix}${propertyName}.${internalName}`;

	const handleChange = (fieldName: string, value: unknown) => {
		if (value === null) {
			setValue(`${name}.status`, StatusType.EMPTY);
		} else {
			setValue(`${name}.status`, StatusType.FILLED);
		}

		setValue(fieldName, value, { shouldValidate: true });
	};

	const dictionaryFilter = (dictionary: DictionaryItem[]): DictionaryItem[] => {
		if (fieldDictionary) {
			return fieldDictionary?.filter(option => option.name.toLowerCase().includes(filterValue.toLowerCase().trim()));
		} else {
			return dictionary;
		}
	};

	return (
		<Controller
			name={`${name}.value`}
			control={control}
			rules={{ validate: validate }}
			render={({ field, fieldState }) => (
				<div>
					<div className={s.fieldWrapper}>
						<Select
							{...field}
							className={s.select}
							onChange={e => {
								e.stopPropagation();
								handleChange(field.name, e.target.value);
							}}
							size="small"
							onClose={() => setFilterValue('')}
						>
							<div
								className={s.searchBar}
								onClick={event => event.stopPropagation()}
							>
								<input
									value={filterValue}
									type="text"
									placeholder="Search..."
									onClick={event => event.stopPropagation()}
									onChange={event => setFilterValue(event.currentTarget.value)}
									onKeyDown={event => event.stopPropagation()}
									className={s.input}
									ref={inputRef}
								/>
							</div>

							{/*@ts-ignore*/}
							<MenuItem value={null}>Unknown</MenuItem>
							{fieldDictionary &&
								dictionaryFilter(fieldDictionary).map((dictionary: any) => (
									<MenuItem
										key={dictionary.id}
										value={dictionary.id.toString()}
									>
										{dictionary.name}
									</MenuItem>
								))}
						</Select>

						{fieldDictionary && (
							<ValueDictionaryToolTip
								title={field.value}
								fieldDictionary={fieldDictionary}
							/>
						)}
					</div>

					{fieldState.error && <span className={s.error}>{fieldState.error.message}</span>}
				</div>
			)}
		/>
	);
});
