import React from 'react';
import s from './SingleInfo.module.scss';
import { DivProps } from 'src/shared/types/props';
import cn from 'classnames';

interface Props extends DivProps{
	caption: string,
	info: string | number,
}

export const SingleInfo: React.FC<Props> = props => {
	const {
		caption,
		info,
		className,
		...divProps
	} = props

	// * Render
	return (
		<div {...divProps} className={cn(s.container, className)}>
			<div>{caption}:</div>
			<div className={s.info}>{String(info)}</div>
		</div>
	);
};
