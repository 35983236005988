import React from 'react';
import { CriticalErrorModalContent } from '../CriticalErrorModalContent';
import { actionsNotifications } from '../../_BLL/notifications/slice';
import { NotificationProvider } from './NotificationProvider';
import { useAppDispatch, useAppSelector } from 'src/app/BLL/createAction';
import { Modal } from 'src/shared/ui/Modal/Modal';

export const NotificationController = () => {

	// * Selectors
	const notifications = useAppSelector(state => state.notifications.notifications);
	const error = useAppSelector(state => state.notifications.error);

	// * Actions
	const dispatch = useAppDispatch();
	const {clearError} = actionsNotifications;

	// * Render
	return (<>
		<Modal
			isOpen={!!error}
			onClose={() => dispatch(clearError())}
		>
			<CriticalErrorModalContent error={error}/>
		</Modal>
		<NotificationProvider notifications={notifications}/>
	</>);
};
