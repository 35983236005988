import React from 'react';
import { IntegerFloatField } from 'src/pages/company/ui/dataPointGenerator/fields/IntegerFloatField/IntegerFloatField';
import { StringField } from 'src/pages/company/ui/dataPointGenerator/fields/StringField/StringField';
import { BooleanField } from 'src/pages/company/ui/dataPointGenerator/fields/BooleanField/BooleanField';
import { PercentageField } from 'src/pages/company/ui/dataPointGenerator/fields/PercentageField/PercentageField';
import { DropDownField } from 'src/pages/company/ui/dataPointGenerator/fields/DropDownField/DropDownField';
import { DropDownManyField } from 'src/pages/company/ui/dataPointGenerator/fields/DropDownManyField/DropDownManyField';
import { StringManyField } from 'src/pages/company/ui/dataPointGenerator/fields/StringManyField/StringManyField';
import { BooleanDropDown } from 'src/pages/company/ui/dataPointGenerator/fields/BooleanDropDown/BooleanDropDown';
import { DeadlineField } from 'src/pages/company/ui/dataPointGenerator/fields/DeadlineField/DeadlineField';
import { DateField } from 'src/pages/company/ui/dataPointGenerator/fields/DateField/DateField';
import { DataPointMeta, FieldDictionary, PropertyHandlingType, ValidateFunction } from '../../_BLL/types';
import { Dictionaries } from 'src/pages/app/_BLL/metaDictionaries/types';

interface Props {
	namePrefix: string;
	handlingType: PropertyHandlingType;
	propertyName: string;
	datapointMeta: DataPointMeta;
	dictionaries: Dictionaries;
}

export const DataPointGenerator: React.FC<Props> = props => {
	const {
		namePrefix,
		handlingType,
		propertyName,
		datapointMeta: {
			internalName,
			fieldType,
			isEditable, // ? There are no uneditable fields.
			allowsNull,
			allowsMany,
			measureUnits,
			rangeLow,
			rangeHigh,
			dictionary,
			options,
		},
		dictionaries,
	} = props;

	const fieldDictionary = dictionaries && dictionary && (dictionaries[dictionary] as FieldDictionary);

	const validate: ValidateFunction = (value: any) => {
		let error;

		if (typeof value === 'string' && value.length > 400) {
			error = 'Value must be less then 400 characters';
		}

		if (rangeLow && rangeHigh === undefined) {
			if (+value < rangeLow && value !== null) {
				error = `Value must be higher then ${rangeLow}`;
			}
		}

		if (rangeHigh && rangeLow === undefined) {
			if (+value > rangeHigh && value !== null) {
				error = `Value must be smaller then ${rangeHigh}`;
			}
		}

		if (rangeLow && rangeHigh) {
			if ((+value < rangeLow || +value > rangeHigh) && value !== null) {
				error = `Value must be between ${rangeLow} and ${rangeHigh}`;
			}
		}

		if (!allowsNull && value === null) {
			error = 'Field cannot be empty';
		}

		return error;
	};

	return (
		<>
			{internalName && (
				<>
					{fieldType === 'Integer' && (
						<IntegerFloatField
							namePrefix={namePrefix}
							propertyName={propertyName}
							internalName={internalName}
							validate={validate}
							measureUnits={measureUnits}
							valueType={fieldType}
						/>
					)}

					{fieldType === 'Id' && (
						<IntegerFloatField
							namePrefix={namePrefix}
							propertyName={propertyName}
							internalName={internalName}
							validate={validate}
							measureUnits={measureUnits}
							valueType={fieldType}
						/>
					)}

					{fieldType === 'Float' && (
						<IntegerFloatField
							namePrefix={namePrefix}
							propertyName={propertyName}
							internalName={internalName}
							validate={validate}
							measureUnits={measureUnits}
							valueType={fieldType}
						/>
					)}

					{fieldType === 'YearAge' && (
						<IntegerFloatField
							namePrefix={namePrefix}
							propertyName={propertyName}
							internalName={internalName}
							validate={validate}
							measureUnits={measureUnits}
							valueType={fieldType}
						/>
					)}

					{fieldType === 'AgeFloat' && (
						<IntegerFloatField
							namePrefix={namePrefix}
							propertyName={propertyName}
							internalName={internalName}
							validate={validate}
							measureUnits={measureUnits}
							valueType={fieldType}
						/>
					)}

					{fieldType === 'String' && (
						<>
							{allowsMany ? (
								<StringManyField
									namePrefix={namePrefix}
									propertyName={propertyName}
									internalName={internalName}
									validate={validate}
									measureUnits={measureUnits}
								/>
							) : (
								<StringField
									namePrefix={namePrefix}
									propertyName={propertyName}
									internalName={internalName}
									validate={validate}
									measureUnits={measureUnits}
								/>
							)}
						</>
					)}

					{fieldType === 'Boolean' && (
						<BooleanField
							namePrefix={namePrefix}
							propertyName={propertyName}
							internalName={internalName}
							validate={validate}
							options={options}
						/>
					)}

					{fieldType === 'BooleanDropDown' && (
						<BooleanDropDown
							namePrefix={namePrefix}
							propertyName={propertyName}
							internalName={internalName}
							validate={validate}
							options={options}
						/>
					)}

					{fieldType === 'Percentage' && (
						<PercentageField
							namePrefix={namePrefix}
							propertyName={propertyName}
							internalName={internalName}
							validate={validate}
							measureUnits={measureUnits}
							handlingType={handlingType}
						/>
					)}

					{fieldType === 'DropDown' && (
						<>
							{allowsMany ? (
								<DropDownManyField
									namePrefix={namePrefix}
									propertyName={propertyName}
									internalName={internalName}
									validate={validate}
									fieldDictionary={fieldDictionary}
								/>
							) : (
								<DropDownField
									namePrefix={namePrefix}
									propertyName={propertyName}
									internalName={internalName}
									validate={validate}
									fieldDictionary={fieldDictionary}
								/>
							)}
						</>
					)}

					{fieldType === 'Date' && (
						<>
							{internalName === 'deadline' ? (
								<DeadlineField
									namePrefix={namePrefix}
									propertyName={propertyName}
									internalName={internalName}
								/>
							) : (
								<DateField
									namePrefix={namePrefix}
									propertyName={propertyName}
									internalName={internalName}
									measureUnits={measureUnits}
								/>
							)}
						</>
					)}
				</>
			)}
		</>
	);
};
