import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import s from './CompanyForm.module.scss';
import { FormProvider, useForm } from 'react-hook-form';
import { FormGroupCompany } from 'src/pages/company/ui/FormGroupCompany';
import { Company, DashBoardMenuName, MetaEntity, PropertyType, SingleMenuItem } from '../../_BLL/types';
import { useAppDispatch } from 'src/app/BLL/createAction';
import { preventSubmitOnEnter } from 'src/helpers/formHelpers';
import { RatingType } from '../../../MainPage/_BLL/types';
import { OwnCompanyTermsOfUse } from 'src/pages/company/ui/OwnCompanyTermsOfUse/OwnCompanyTermsOfUse';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/BLL/rootReducer';
import { ButtonRounded } from 'src/shared/ui/buttons/ButtonRounded';
import { ProgressCalcCompany } from '../ProgressCalcCompany';
import { ObjectToArray } from 'src/helpers/dataHelpers';
import { SubmitForRating } from '../SubmitForRating';
import { actionsCompany } from 'src/pages/company/_BLL/slice';
import { Nav } from 'src/entities/Menu';

interface Props {
	companyMeta: MetaEntity;
	currentCompany: Company;
	ratingType: RatingType;
}

// ! No need for memo.
export const CompanyForm: React.FC<Props> = props => {
	const {
		companyMeta, //
		currentCompany,
		ratingType,
	} = props;

	// * Selectors
	const menuItems = useSelector((state: RootState) => state.company.menuItems);
	const activeMenuName = useSelector((state: RootState) => state.company.activeMenu);
	const status = useSelector((state: RootState) => state.company.status);

	// * Actions
	const dispatch = useAppDispatch();
	const { updateCompany, setActiveMenuOption } = actionsCompany;

	const handleSetActiveMenuOption = (menuItem: DashBoardMenuName) => dispatch(setActiveMenuOption(menuItem));

	// * Company meta
	const companyActiveMenuMeta = useMemo(() => companyMeta.tabs.find(tab => tab.name === activeMenuName), [companyMeta.tabs, activeMenuName]);

	// * Company Form
	const companyFormMethods = useForm({
		defaultValues: currentCompany,
	});

	const { handleSubmit, reset, getValues, formState, trigger } = companyFormMethods;

	useEffect(() => {
		reset(currentCompany);
	}, [currentCompany]);

	const onSubmit = (values: Company) => {
		const requestBody: Company = {
			...values,
		};

		dispatch(updateCompany(requestBody));
	};

	const submitForm = handleSubmit(onSubmit);

	const errorsCount = useMemo(() => formState.errors?.properties && Object.keys(formState.errors.properties).length, [formState]);

	// * Menu
	const onMenuClick = useCallback(
		(menuItemName: DashBoardMenuName) => {
			if (activeMenuName !== 'Welcome' && !errorsCount) {
				handleSetActiveMenuOption(menuItemName);
			}
		},
		[errorsCount, activeMenuName],
	);

	const menuNamesWithIndex = useMemo(
		() =>
			menuItems[ratingType]?.map((item: SingleMenuItem, index: number) => ({
				name: item.name,
				index,
			})),
		[menuItems, ratingType],
	);

	const findMenuOrder = (activeMenuName: DashBoardMenuName, menuNames?: { name: DashBoardMenuName; index: number }[]) => {
		const menuIndex = menuNames?.find(item => item.name === activeMenuName)?.index;
		return menuIndex !== undefined ? menuIndex : 111; // ? Should not happen, but 111 Means fail.
	};

	useEffect(() => {
		return () => {
			handleSetActiveMenuOption('Welcome');
		};
	}, []);

	// * Navigation
	const nextMenu = (next: 'forward' | 'back') => {
		const activeMenuIndex = menuNamesWithIndex?.find(menu => menu.name === activeMenuName)?.index;
		const step = next === 'forward' ? activeMenuIndex !== undefined && activeMenuIndex + 1 : activeMenuIndex !== undefined && activeMenuIndex - 1;
		const nextMenuName = activeMenuIndex !== undefined && menuNamesWithIndex?.find(menu => menu.index === step)?.name;
		nextMenuName && handleSetActiveMenuOption(nextMenuName);
	};

	const forward = () => {
		trigger().then(noErrors => {
			if (noErrors) {
				nextMenu('forward');
				activeMenuName !== 'Welcome' && submitForm();
			}
		});
	};

	const backward = () => {
		trigger().then(noErrors => {
			if (noErrors) {
				nextMenu('back');
				activeMenuName !== 'Submit for rating' && submitForm();
			}
		});
	};

	const scrollRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		scrollRef.current && scrollRef.current.scrollTo(0, 0);
	}, [activeMenuName]);

	const nextButtonText = (activeMenuName: DashBoardMenuName) => {
		switch (activeMenuName) {
			case 'Welcome':
				return 'Agree and continue';
			case 'Sexuality':
				return 'Submit for rating';
			default:
				return 'Next';
		}
	};

	// * Render
	return (
		<form onKeyDown={e => preventSubmitOnEnter(e)}>
			{companyMeta.tabs.map(tab => (
				<ProgressCalcCompany
					key={tab.name}
					formValues={ObjectToArray(getValues().properties) as { name: string; data: PropertyType }[]}
					tabMeta={tab}
					ratingType={ratingType}
				/>
			))}

			<FormProvider {...companyFormMethods}>
				<div className={s.inner}>
					<Nav
						menuItems={menuItems[ratingType]}
						activeMenuItem={activeMenuName}
						onMenuClick={onMenuClick}
					/>

					<div className={s.formContent}>
						<div
							className={s.group}
							ref={scrollRef}
						>
							{activeMenuName === 'Welcome' && (
								<div
									data-test={'terms_of_use'}
									className={s.formGroupContainer}>
									<OwnCompanyTermsOfUse/>
								</div>
							)}

							{activeMenuName === 'Submit for rating' && (
								<div className={s.formGroupContainer}>
									<SubmitForRating />
								</div>
							)}

							<div className={s.formGroupContainer}>
								{companyActiveMenuMeta &&
									companyActiveMenuMeta.groups.map((group, index) => (
										<FormGroupCompany
											key={group.name}
											menuOrder={findMenuOrder(activeMenuName, menuNamesWithIndex)}
											groupOrder={index + 1}
											companyMetaGroup={group}
										/>
									))}
							</div>
						</div>

						{/* Form navigation and save */}
						{activeMenuName !== 'Submit for rating' && (
							<div className={s.buttonsWrapper}>
								{activeMenuName !== 'Welcome' && (
									<ButtonRounded
										onClick={backward}
										status={status}
									>
										Previous
									</ButtonRounded>
								)}

								<ButtonRounded
									data-test={'agree_or_next_button'}
									onClick={forward}
									status={status}
								>
									{nextButtonText(activeMenuName)}
								</ButtonRounded>

								{errorsCount && (
									<span className={s.error}>
										Please fill {errorsCount} field{errorsCount > 1 ? 's' : ''} before continue.
									</span>
								)}
							</div>
						)}
					</div>
				</div>
			</FormProvider>
		</form>
	);
};
