import { Dictionaries } from './types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiRequest } from 'src/app/api/api';
import { RequestStatus, UrlAPI } from 'src/app/api/types';

const NAME = 'metaDictionaries';

const getDictionaries = createAsyncThunk(`${NAME}/getDictionaries`, async (payload: { token?: string }, thunkAPI) => {
	return await apiRequest.getRequest<Dictionaries>({
		url: UrlAPI.dictionaries,
		thunkAPI,
		token: payload.token
	});
});

// * Reducer
interface State {
	dictionaries: Dictionaries | null;
	// columnsMeta: ColumnsMeta | null;
	lastUpdated: string | null;
	status: RequestStatus;
}

export const initialState: State = {
	dictionaries: null,
	// columnsMeta: null,
	lastUpdated: null,
	status: RequestStatus.still
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getDictionaries.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getDictionaries.fulfilled, (state, action) => {
			state.dictionaries = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(getDictionaries.rejected, state => {
			state.status = RequestStatus.failed;
		});

		// builder.addCase(getColumnsMeta.pending, state => {
		// 	state.status = RequestStatus.loading;
		// });
		// builder.addCase(getColumnsMeta.fulfilled, (state, action) => {
		// 	state.columnsMeta = action.payload;
		// 	state.lastUpdated = DateTime.now().toString();
		// 	state.status = RequestStatus.still;
		// });
		// builder.addCase(getColumnsMeta.rejected, state => {
		// 	state.status = RequestStatus.failed;
		// });
	}
});

export const actionsMetaDictionaries = {
	...slice.actions,
	getDictionaries,
	// getColumnsMeta
};
