import React, { ChangeEvent, memo } from 'react';
import s from './PercentageField.module.scss';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import InputAdornment from '@mui/material/InputAdornment';
import { TextField } from '@mui/material';
import { GeneratedField, StatusType } from 'src/pages/company/_BLL/types';

export const PercentageField: React.FC<GeneratedField> = memo(props => {
	const { namePrefix, propertyName, internalName, validate, measureUnits, handlingType } = props;

	// * Form
	const { setValue, control, trigger } = useFormContext();

	const existence = useWatch({
		name: `${namePrefix}${propertyName}.existence.value`,
	});

	const name = `${namePrefix}${propertyName}.${internalName}`;

	// Delta percentage handling type only...
	const percentageName = `${namePrefix}${propertyName}.percentage`;
	const deltaName = `${namePrefix}${propertyName}.delta`;
	//...

	const textFieldOnChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, fieldName: string) => {
		if (handlingType === 'normal' || handlingType === 'target') {
			if (event.target.value === '' || event.target.value === '.') {
				setValue(`${name}.status`, StatusType.EMPTY);
				setValue(fieldName, null);
			} else if (event.target.value.match(/^[-0-9]*\.?[0-9]*$/g)) {
				setValue(`${name}.status`, StatusType.FILLED);
				setValue(fieldName, event.target.value);
			} else return;
		}

		if (handlingType === 'deltaPercentage') {
			if (event.target.value === '' || event.target.value === '.') {
				setValue(`${percentageName}.status`, StatusType.EMPTY);
				setValue(`${deltaName}.status`, StatusType.EMPTY);
				setValue(`${percentageName}.value`, null);
				setValue(`${deltaName}.value`, null);
			} else if (event.target.value.match(/^[-0-9]*\.?[0-9]*$/g)) {
				setValue(`${percentageName}.status`, StatusType.FILLED);
				setValue(`${deltaName}.status`, StatusType.FILLED);

				if (internalName === 'delta') {
					setValue(`${deltaName}.value`, event.target.value);

					const percentageValue = (100 - +event.target.value).toString();

					setValue(`${percentageName}.value`, percentageValue);
				}

				if (internalName === 'percentage') {
					setValue(`${percentageName}.value`, event.target.value);

					const deltaValue = (100 - +event.target.value).toString();

					setValue(`${deltaName}.value`, deltaValue);
				}
			} else return;
		}
	};

	// Target Fields only ...
	const disableBasedOnExistence = existence === null || existence === 'false';

	return (
		<Controller
			name={`${name}.value`}
			control={control}
			rules={{ validate: validate }}
			render={({ field, fieldState }) => (
				<div>
					<div className={s.fieldWrapper}>
						{handlingType === 'deltaPercentage' && <span className={s.additionalTextLeft}>{internalName}</span>}
						<TextField
							{...field}
							className={s.textField}
							value={field.value === null ? '' : field.value}
							onChange={e => textFieldOnChangeHandler(e, field.name)}
							onBlur={() => trigger()}
							disabled={disableBasedOnExistence}
							size="small"
							InputProps={{
								endAdornment: <InputAdornment position="end">{measureUnits}</InputAdornment>,
							}}
						/>
					</div>

					{fieldState.error && <span className={s.error}>{fieldState.error.message}</span>}
				</div>
			)}
		/>
	);
});
