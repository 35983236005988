import React, { ChangeEvent, memo } from 'react';
import s from './DateField.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import Tooltip from '@mui/material/Tooltip';
import { TextField } from '@mui/material';
import { GeneratedField, StatusType } from 'src/pages/company/_BLL/types';
import { nullToString } from 'src/pages/company/ui/dataPointGenerator/fields/_helpers/valueHelpers';

export const DateField: React.FC<Omit<GeneratedField, 'validate'>> = memo(props => {
	const { namePrefix, propertyName, internalName, measureUnits } = props;

	// * Form
	const { setValue, control, trigger } = useFormContext();

	const name = `${namePrefix}${propertyName}.${internalName}`;

	const textFieldOnChangeHandler = (event: ChangeEvent<HTMLDataElement>, fieldName: string) => {
		if (event.currentTarget.value.trim() === '') {
			setValue(fieldName, null);
			setValue(`${name}.status`, StatusType.EMPTY);
		} else {
			setValue(fieldName, event.target.value);
			setValue(`${name}.status`, StatusType.FILLED);
		}
	};

	const addZeroToMonth = (date: number) => {
		const dateString = date.toString();

		if (dateString.length === 1) {
			return '0' + dateString;
		} else return dateString;
	};

	const addZeroToYear = (date: number) => {
		const dateString = date.toString();

		if (dateString.length === 1) {
			return '000' + dateString;
		} else if (dateString.length === 2) {
			return '00' + dateString;
		} else if (dateString.length === 3) {
			return '0' + dateString;
		} else return dateString;
	};

	const changeValueFormat = (value: string | null) => {
		if (value === null) return '';

		const date = new Date(value);

		return `${addZeroToYear(date.getFullYear())}-${addZeroToMonth(date.getUTCMonth() + 1)}-${addZeroToMonth(date.getDate())}`;
	};

	// * Component
	return (
		<Controller
			name={`${name}.value`}
			control={control}
			render={({ field, fieldState }) => (
				<Tooltip
					title={nullToString(field.value)}
					placement="right-start"
				>
					<div>
						<div className={s.fieldWrapper}>
							<TextField
								{...field}
								className={s.textField}
								type="Date"
								onChange={e => textFieldOnChangeHandler(e, field.name)}
								onBlur={() => trigger()}
								value={changeValueFormat(field.value)}
								size="small"
							/>

							{measureUnits && <span className={s.additionalTextRight}>{measureUnits}</span>}
						</div>

						{fieldState.error && <span className={s.error}>{fieldState.error.message}</span>}
					</div>
				</Tooltip>
			)}
		/>
	);
});
