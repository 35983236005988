import React from 'react';
import s from 'src/widgets/CertificatesMC/ui/CertificatesMC.module.scss';
import { RatingWidgetDownload } from 'src/features/RatingWidgetDownload';
import { CertificateDownload } from 'src/features/CertificateDownload';
import { Heading } from 'src/shared/ui/Heading';

interface Props {
	companyId: number;
	companyName: string;
	score: number;
	absoluteRating: string;
	relativeRating: string;
}

export const CertificatesMC: React.FC<Props> = props => {
	const {
		companyId,
		companyName,
		score,
		absoluteRating,
		relativeRating
	} = props;

	const dateOfIssue = new Date();

	// * Render
	return (<>
		<Heading level={3} marginBottom="m">Certificates & widgets</Heading>

		<div className={s.inner}>
			<CertificateDownload
				companyId={companyId}
				companyName={companyName}
				score={score}
				absoluteRating={absoluteRating}
				relativeRating={relativeRating}
				dateOfIssue={dateOfIssue}
			/>

			<RatingWidgetDownload
				dateOfIssue={dateOfIssue}
				score={score}
				absoluteRating={absoluteRating}
				relativeRating={relativeRating}
			/>
		</div>
	</>);
};
