import React from 'react';
import s from './MainPage.module.scss';
import { Welcome } from 'src/features/Welcome';
import { ServicesList } from 'src/features/ServicesList';
import { PageDecorator } from 'src/app/ui/decorators/PageDecorator';

export const MainPage: React.FC = () => {

	// * Render
	return (
		<PageDecorator>
			<div className={s.content}>
				<Welcome/>
				<ServicesList/>
			</div>
		</PageDecorator>
	);
};
